<template>
  <div>
    <!-- Snackbar cargando -->
    <v-snackbar v-model="isLoadingTrack" bottom :timeout="-1">
      Descargando datos del track
      <template #action="{ attrs }">
        <v-progress-circular v-on="attrs" indeterminate />
      </template>
    </v-snackbar>

    <!-- Snackbar errores -->
    <v-snackbar v-model="showSnackBar" color="error" bottom :timeout="5000">
      {{ msgSnackBar }}
    </v-snackbar>

    <vx-popup
      :datosPopup="datosPopup" :showPopup="showPopup" :showButtonTimeSelectorIsochrone="showButtonTimeSelectorIsochrone"
      @clickBtn="clickBtn" @loadTrackFechas="loadTrackFechas" @isochroneClick="isochroneClick"
    />

    <vx-track-fechas :show="showFechasDialog" @cancelar="showFechasDialog = false" @aceptar="loadTrackFechas" />
    <vx-dialog-set-coordenadas :show="showRoutingDialog" @cerrar="closeRouteDialog" :medio="medio" @openToast="isInfoToastRouteVisible = true" />
    <!-- Toast -->
    <v-snackbar
      v-model="isInfoToastRouteVisible"
      timeout="-1"
      bottom
      center
      elevation="6"
      shaped
      transition="slide-y-transition"
    >
      <span>{{ messageForToastRoute }}</span>

      <template #action="{ attrs }">
        <v-btn color="red" text @click="closeRouteDialog(null)" v-bind="attrs">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import api from '@/api'
import constants from '@/helpers/constants'
import * as ArcGIS from '@/helpers/ArcGIS'

import Popup from './Popup.vue'
import TrackFechas from './TrackFechas.vue'
import DialogSetCoordenadas from '../DialogSetCoordenadas.vue'

export default {
  components: {
    'vx-popup': Popup,
    'vx-track-fechas': TrackFechas,
    'vx-dialog-set-coordenadas': DialogSetCoordenadas
  },

  data: () => ({
    showPopup: false,
    rawPopupData: null, // Datos que devuelve el mapa
    attributes: null,

    datosPopup: null, // Datos parseados que se le pasan al popup

    showFechasDialog: false,
    showRoutingDialog: false,
    showRoutingIsochrone: false,

    isLoadingTrack: false,
    tracksActivos: [],

    showSnackBar: false,
    msgSnackBar: '',

    medio: null,
    isInfoToastRouteVisible: false,
    messageForToastRoute: '',

    showButtonTimeSelectorIsochrone: false

  }),

  computed: {
  },

  watch: {
  },

  methods: {
    onShowPopup (data) {
      this.rawPopupData = data
      this.datosPopup = null
      if (data) {
        this.attributes = data.attributes
        this.generarDatosMaptip()

        if (!this.attributes.notShowMaptip) {
          this.showPopup = true
        }
      } else {
        this.showPopup = false
      }
    },

    generarDatosMaptip () {
      let layerId = this.rawPopupData.layer
      let attributes = this.rawPopupData.attributes

      let props = {
        x: this.rawPopupData.position.x,
        y: this.rawPopupData.position.y
      }
      let attrs = []
      let buttons = []

      this.showButtonTimeSelectorIsochrone = false

      if (layerId === 'layer-medios') {
        if (!attributes.notShowMaptip) {
          // Obtenemos los medios
          let medios = this.$store.getters['medio/allMedios']

          this.showButtonTimeSelectorIsochrone = true
          // Filtramos los medios que tengan dispositivos asociados, con el medio clicado en el mapa (popup)
          // let medioDispositivos = medios.filter(x => x.MEDIO === attributes.MEDIO && x.MEDIO_DISPOSITIVOS)
          this.medio = medios.find(x => x.ID_MEDIO === attributes.ID_MEDIO)
          if (this.medio) {
            let dispositivos = this.medio.MEDIO_DISPOSITIVOS

            let medioDispositivosAsociados = dispositivos.map(x => x.PROVEEDOR + ' - ' + x.CODIGO_EXTERNO)

            props.titulo = attributes.MEDIO

            let listaDispositivosTxt = ''
            for (let i = 0; i < medioDispositivosAsociados.length; i++) {
              listaDispositivosTxt += medioDispositivosAsociados[i]
              if (i < medioDispositivosAsociados.length - 1) {
                listaDispositivosTxt += ', '
              }
            }
            attrs = [
              // {name: 'Localidad', value: 'servicio????'},
              { name: 'Nombre', value: attributes.NOMBRE },
              { name: 'Tel. interno', value: attributes.TELEFONO_INTERNO },
              { name: 'Tel. externo', value: attributes.TELEFONO_EXTERNO },
              { name: 'Zona', value: attributes.ZONA },
              { name: 'Guardias', value: attributes.GUARDIAS },
              { name: 'Fecha ult. señal', value: this.$date.formatDate(attributes.FECHA, 'DD/MM/YYYY HH:mm') },
              { name: 'Tipo', value: attributes.TIPO },
              { name: 'Categoría', value: attributes.CATEGORIA },
              { name: 'Hora entrada', value: attributes.HORA_ENTRADA },
              { name: 'Hora salida', value: attributes.HORA_SALIDA },
              { name: 'Proveedor', value: attributes.PROVEEDOR },
              { name: 'Dispositivo/s', value: listaDispositivosTxt.length > 0 ? listaDispositivosTxt : '-' }
            ]
          }

          /**
           * Distinguir entre dispositivo o medio
           */
          // TODO: Aqui habrá problemas al cambiar de pintar 1 medio a pintar todos los dispositivos
          let trackActivo = this.tracksActivos.indexOf(this.attributes.ID_DISPOSITIVO) > -1

          buttons = [
            // {id: 'zoom', icon: 'zoom_in', label: 'Zoom medio', visible: true},
            { id: 'showTrack', icon: 'mdi-map-marker', label: 'Mostrar ruta', visible: !trackActivo },
            { id: 'hideTrack', icon: 'mdi-map-marker-off', label: 'Ocultar ruta', visible: trackActivo },

            { id: 'showRouting', icon: 'mdi-directions', label: 'Ir a', visible: !this.showRoutingIsochrone },
            { id: 'hideRouting', icon: 'mdi-eye-off', label: 'Ocultar Ir a', visible: this.showRoutingIsochrone },

            { id: 'showTrackFechas', icon: 'mdi-calendar', label: 'Mostrar ruta entre fechas', visible: !trackActivo },
            { id: 'exportTrack', icon: 'mdi-download', label: 'Descargar SHP y KML', visible: trackActivo }
          ]
        }
      } else if (layerId === 'layer-track') {
        props.titulo = 'Track ' + attributes.MEDIO

        let velocidad = 0
        if (attributes.VELOCIDAD) {
          velocidad = attributes.VELOCIDAD
        }

        attrs = [
          { name: 'Fecha', value: this.$date.formatDate(attributes.FECHA, 'DD/MM/YYYY HH:mm') },
          { name: 'Velocidad', value: velocidad + ' km/h' }
        ]
      } else if (layerId === 'layer-incendios') {
        props.titulo = attributes.LOCALIDAD

        attrs = [
          { name: 'Municipio', value: attributes.MUNICIPIO },
          { name: 'Localidad', value: attributes.LOCALIDAD },
          { name: 'Observaciones', value: attributes.OBSERVACIONES }
        ]

        buttons = [
          // {id: 'zoom', icon: 'zoom_in', tooltip: 'Zoom incendio', show: true}
        ]
      } else if (['layer-avion', 'layer-bomberos', 'layer-BRIF', 'layer-central-comunicaciones', 'layer-helicoptero', 'layer-pick-up', 'layer-repetidor-comunicaciones', 'layer-reten', 'layer-autobomba', 'layer-SOS', 'layer-torreta-vigilancia'].includes(layerId)) {
        props.titulo = attributes.TIPO

        attrs = [
          { name: 'Identificador', value: attributes.DENOMINAC },
          { name: 'Municipio', value: attributes.MUNICIPIO },
          { name: 'Altitud ', value: attributes.ALTITUD }
        ]
      } else if (layerId === 'layer-municipios') {
        props.titulo = 'Municipio'

        attrs = [
          { name: 'Municipio', value: attributes.TM }
        ]
      } else if (layerId === 'layer-punto-encuentro') {
        props.titulo = 'Puntos de encuentro'

        attrs = [
          { name: 'Identificador', value: attributes.PUNTO },
          { name: 'Nombre', value: attributes.NOMBRE },
          { name: 'Municipio', value: attributes.MUNICIPIO },
          { name: 'Ubicación', value: attributes.UBICACION }
        ]
      } else if (layerId === 'layer-punto-agua-aer' || layerId === 'layer-punto-agua-ter') {
        props.titulo = 'Puntos de agua'

        attrs = [
          { name: 'Identificador', value: attributes.PUNTO },
          { name: 'Demarcación', value: attributes.DEMARCACIO },
          { name: 'Municipio', value: attributes.MUNICIPIO },
          { name: 'Núcleo de población', value: attributes.NUC_URB },
          { name: 'Paraje', value: attributes.PARAJE },
          { name: 'Superficie', value: attributes.SUPERFICIE },
          { name: 'Tipo', value: attributes.TIPO },
          { name: 'Estacional', value: attributes.ESTACIONAL },
          { name: 'Capacidad', value: attributes.CAPACIDAD },
          { name: 'Medios aéreos', value: attributes.MED_AEREO },
          { name: 'Altitud', value: attributes.ALTITUD },
          { name: 'Observaciones', value: attributes.OBSERVAC }
        ]
      } else if (layerId === 'layer-estaciones-meteorologicas') {
        props.titulo = attributes.DENOMINACI

        attrs = [
          { name: 'Tipo ', value: attributes.TIPO },
          { name: 'Denominación:', value: attributes.DENOMINACI }
        ]
      } else if (layerId === 'layer-montes-utilidad') {
        props.titulo = 'Montes utilidad pública'

        attrs = [
          { name: 'Identificador', value: attributes.id },
          { name: 'Nombre ', value: attributes.Nombre },
          { name: 'Código', value: attributes.Código_de },
          { name: 'Brigada', value: attributes.Brigada },
          { name: 'Comarca', value: attributes.Comarca },
          { name: 'Partido judicialicial', value: attributes.Partido_jud },
          { name: 'Municipio', value: attributes.Municipio },
          { name: 'Titular', value: attributes.Titular },
          { name: 'Superficie', value: attributes.Superficie }
        ]
      } else if (layerId === 'layer-plan-operaciones') {
        props.titulo = 'Plan de operaciones'

        attrs = []
        if (attributes.LONGITUD) {
          attrs.push({ name: 'Longitud', value: (Math.round(((attributes.LONGITUD / 1000) * 100)) / 100) + ' km' })
        }

        if (attributes.AREA) {
          attrs.push({ name: 'Área', value: (Math.round(((attributes.AREA / 10000) * 100)) / 100) + ' ha' })
        }
      } else if (layerId === 'layer-emergencias') {
        props.titulo = 'Emergencia'

        attrs = [
          { name: 'Medio', value: attributes.MEDIO },
          { name: 'Fecha', value: attributes.FECHA }
        ]
      } else if (layerId === 'layer-infraestructuras') {
        props.titulo = attributes.NOMBRE
        attrs = [
          { name: 'Tipo', value: attributes.TIPO },
          { name: 'Zona', value: attributes.ZONA },
          { name: 'ETRS89 H30N X', value: attributes.X },
          { name: 'ETRS89 H30N Y', value: attributes.Y }
        ]
      } else if (layerId === 'layer-infraestructuras-propias') {
        props.titulo = attributes.NOMBRE
        attrs = [
          { name: 'Tipo', value: attributes.TIPO },
          { name: 'Categoria', value: attributes.CATEGORIA },
          { name: 'Codigo', value: attributes.CODIGO },
          { name: 'Municipio', value: attributes.MUNICIPIO },
          { name: 'Provincia', value: attributes.PROVINCIA },
          { name: 'Responsable', value: attributes.RESPONSABLE },
          { name: 'Fecha alta', value: this.$date.parseDate(attributes.FECHA_ALTA).format('DD/MM/YYYY') },
          { name: 'Fecha baja', value: this.$date.parseDate(attributes.FECHA_BAJA).format('DD/MM/YYYY') },
          { name: 'Descripcion', value: attributes.DESCRIPCION },
          { name: 'Direccion', value: attributes.DIRECION }
        ]
      } else if (layerId === 'layer-perimetros') {
        props.titulo = 'Perímetro'
        attrs = [
          { name: 'Área (ha)', value: attributes.AREA },
          { name: 'Perímetro (km)', value: attributes.PERIMETRO }
        ]
      }

      this.datosPopup = {
        properties: props,
        attributes: attrs,
        buttons: buttons
      }

      /* if (!this.showIncendiosCards) {
        this.datosPopup.properties.x += 500
      } */
    },

    clickBtn (btn) {
      switch (btn) {
        case 'zoom':
          this.zoom()
          break
        case 'showTrack':
          this.loadTrack()
          break
        case 'hideTrack':
          this.hideTrack()
          break
        case 'exportTrack':
          this.exportTrack()
          break
        case 'showTrackFechas':
          this.showFechasDialog = true
          break
        case 'showRouting':
          this.showRoutingDialog = true
          break
        case 'hideRoutingIsocrona':
          ArcGIS.clearRutas()
          break
        case 'showRoutingIsocrona':
          // Add your logic here if needed
          break
        default:
          break
      }

      this.showPopup = false
    },
    zoom () {
      let center = {
        target: [this.attributes.LONGITUDE, this.attributes.LATITUDE],
        zoom: 15
      }

      ArcGIS.setCenterMap(center)
    },

    async isochroneClick (times) {
      try {
        let location = {
          lat: this.medio.ULT_POSICION.LATITUD,
          lon: this.medio.ULT_POSICION.LONGITUD
        }
        const data = {
          location: location,
          time: times
        }
        const dataResponse = await api.ruta.calcularIsochrone(data)
        if (dataResponse.data) {
          ArcGIS.showRutasGeoJSON(dataResponse.data)
          this.$notify({
            title: 'Isócrona',
            text: 'Se ha calculado correctamente',
            type: 'success',
            duraton: 3000
          })
        } else {
          this.$notify({
            title: 'Error en la ruta',
            text: 'Revise los datos ingresados',
            type: 'error',
            duraton: 3000
          })
        }
      } catch (error) {
        console.error('Error al calcular el isochrone:', error)
      }
    },

    async loadTrack () {
      let timeBack = constants.track.timeBackHours

      this.isLoadingTrack = true

      try {
        /**
         * Distinguir entre dispositivo o medio
         */
        let response = await api.medio.getTrackMedio(this.attributes.ID_DISPOSITIVO, timeBack)

        if (response.status === 200) { // TODO: reemplazar por 204 si no devuelve nada
          if (response.data.length >= 2) {
            this.showTrack(response.data)
          } else {
            this.msgSnackBar = 'El medio no dispone de track.'
            this.showSnackBar = true
          }
        }
      } catch (err) {
        this.$log.error('Error al obtener el track', err)
        this.msgSnackBar = 'Error al obtener el track.'
      } finally {
        this.isLoadingTrack = false
      }
    },

    async loadTrackFechas (data) {
      this.isLoadingTrack = true

      this.showFechasDialog = false

      let fechaInit = data.fechaInit
      let fechaFin = data.fechaFin

      try {
        /**
         * Distinguir entre dispositivo o medio
         */
        // let response = this.attributes.ID_DISPOSITIVO
        //   ? await api.medio.getTrackFechasMedio(this.attributes.ID_DISPOSITIVO, fechaInit, fechaFin)
        //   : await api.medio.getTrackFechasMedio(this.medio.ID_MEDIO, fechaInit, fechaFin)

        let response = await api.medio.getTrackFechasMedio(this.attributes.ID_DISPOSITIVO, fechaInit, fechaFin)
        if (response.status === 200) {
          if (response.data.length >= 2) {
            this.showTrack(response.data)
          } else {
            this.msgSnackBar = 'El medio no dispone de track.'
            this.showSnackBar = true
          }
        }

        if (response.status === 204) {
          this.msgSnackBar = 'El medio no dispone de track.'
          this.showSnackBar = true
        }
      } catch (err) {
        this.$log.error('Error al obtener el track', err)
        this.msgSnackBar = 'Error al obtener el track.'
      } finally {
        this.isLoadingTrack = false
      }
    },

    showTrack (posiciones) {
      let maxTimeDiference = constants.track.timeDiferenceTrackMinutes
      let maxPositions = constants.track.maxPositions

      // this.track.ID_MEDIO = posiciones[0].ID_MEDIO
      // this.track.MEDIO = posiciones[0].MEDIO
      // this.track.positions = []

      let positions = []
      for (let i = 0; i < posiciones.length; i++) {
        if (i === 0) {
          // this.track.positions.push(posiciones[i])
          positions.push(posiciones[i])
        } else {
          let dateLastPos = this.$date.parseDate(posiciones[i - 1].FECHA)
          let date = this.$date.parseDate(posiciones[i].FECHA)
          let difMinutes = dateLastPos.diff(date, 'minutes')

          if (difMinutes <= maxTimeDiference && positions.length <= maxPositions) { // this.track.positions
            // this.track.positions.push(posiciones[i])
            positions.push(posiciones[i])
          } else {
            continue
          }
        }
      }

      /**
       * Distinguir entre dispositivo o medio
       */
      let idTrack = this.attributes.ID_DISPOSITIVO
      let nameTrack = this.medio.MEDIO + '-' + this.attributes.PROVEEDOR

      ArcGIS.drawTrack(idTrack, nameTrack, positions, true)
      this.tracksActivos.push(idTrack)
      // this.setExtentTrack(this.track.positions)
      // this.$emit('drawTrack', this.medio.ID_MEDIO, positions) // this.track.positions
      // ArcGIS.drawTrack(this.medio.ID_MEDIO, this.medio.MEDIO, positions)
      // this.tracksActivos.push(this.medio.ID_MEDIO)
    },

    setExtentTrack (positions) {
      let latitud = []
      let longitud = []
      let extent = {}

      for (let i = 0; i < positions.length; i++) {
        latitud.push(positions[i].LATITUD)
        longitud.push(positions[i].LONGITUD)
      }
      extent.xmax = Math.max(...longitud)
      extent.xmin = Math.min(...longitud)
      extent.ymax = Math.max(...latitud)
      extent.ymin = Math.min(...latitud)
      extent.wkid = 4326
      this.$emit('setExtentMap', extent)
    },

    hideTrack () {
      /**
       * Distinguir entre dispositivo o medio
       */
      let index = this.attributes.ID_DISPOSITIVO
        ? this.tracksActivos.indexOf(this.attributes.ID_DISPOSITIVO)
        : this.tracksActivos.indexOf(this.medio.ID_MEDIO)

      if (index > -1) {
        this.tracksActivos.splice(index, 1)
      }
      /**
       * Ocultar dispositivo o medio
       */
      this.attributes.ID_DISPOSITIVO
        ? ArcGIS.hideTrack(this.attributes.ID_DISPOSITIVO)
        : ArcGIS.hideTrack(this.medio.ID_MEDIO)
    },

    exportTrack () {
      /**
       * Distinguir entre dispositivo o medio
       */
      this.attributes.ID_DISPOSITIVO
        ? ArcGIS.exportTrack({ idMedio: this.attributes.ID_DISPOSITIVO, medio: this.attributes.CODIGO_EXTERNO })
        : ArcGIS.exportTrack({ idMedio: this.medio.ID_MEDIO, medio: this.medio.MEDIO })
    },

    closeRouteDialog (showToast) {
      this.showRoutingDialog = false
      if (showToast) {
        this.messageForToastRoute = `🚗 ${showToast.distance} Km | ⏱ ${showToast.time.horas} h ${showToast.time.minutos} min`
        this.isInfoToastRouteVisible = true
      } else {
        ArcGIS.clearRutas()
        this.isInfoToastRouteVisible = false
      }
    }
  },

  mounted () {
    this.$eventHub.$on('showPopup', this.onShowPopup)
    this.$eventHub.$on('showRoutePopup', this.closeRouteDialog)
  }
}
</script>

<style scoped>

</style>
