import api from '@/api'
import router from '@/router'

import jwtDecode from 'jwt-decode'

const Q = require('q')

const state = {
  listaCamaras: [],
  isCamaraOpen: false
}

const getters = {
  listaCamaras: (state) => state.listaCamaras,
  isCamaraOpen: (state) => state.isCamaraOpen
}

const mutations = {
  SET_LISTA_CAMARA (state, listaCamaras) {
    state.listaCamaras = listaCamaras
  },
  SET_CAMARA_OPEN (state, isCamaraOpen) {
    state.isCamaraOpen = isCamaraOpen
  }
}

const actions = {
  async fetchListaCamaras ({ commit }) {
    const listaCamaras = await api.camara.getCamaras()
    if (listaCamaras.status === 200) {
      // console.log('Lista Camaras: ', listaCamaras.data)
      commit('SET_LISTA_CAMARA', listaCamaras.data)
    }
  },
  async setIsCamaraOpen ({ commit }, isCamaraOpen) {
    let deferred = Q.defer()
    try {
      commit('SET_CAMARA_OPEN', isCamaraOpen)
      deferred.resolve()
    } catch (err) {
      console.error('Error cambiar estado componente camara', err)
      deferred.reject(err)
    }

    return deferred.promise
  },
  async movePTZToPoint ({ commit }, { idCamara, pan }) {
    let deferred = Q.defer()
    try {
      const response = await api.camara.movePTZToPoint(idCamara, pan)
      if (response.status === 200) {
        deferred.resolve()
      }
    } catch (err) {
      console.error('Error al mover PTZ a punto', err)
      deferred.reject(err)
    }

    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
