import DateService from '@/helpers/DateService'
import dayjs from 'dayjs'

export const downloadJSON2CSV = async function (headers, data, fileName) {
  // Paso 1: Crear el contenido del archivo CSV
  const csvRows = []

  // Paso 2: Añadir los encabezados al CSV
  csvRows.push(headers.map(header => {
    const escapedValue = ('' + header.text).replace(/"/g, '""')
    return `"${escapedValue}"`
  }).join(';'))

  // Paso 3: Añadir las filas de datos al CSV
  data.forEach(row => {
    // Solo extraer los valores que corresponden a los headers
    const values = headers.map(header => header.value).map(header => {
      let rawValue = row[header] == null ? '' : row[header]
      if (header === 'ULT_POSICION' && rawValue.FECHA) {
        rawValue = DateService
          .parseDate(rawValue.FECHA)
          .format('DD/MM/YYYY HH:mm')
      }
      const escapedValue = ('' + rawValue).replace(/"/g, '""') // Escapar comillas y manejar valores indefinidos
      return `"${escapedValue}"` // Encerrar en comillas dobles para manejar casos con comas
    })
    csvRows.push(values.join(';'))
  })

  // Paso 4: Convertir el array de filas CSV en un string
  const csvString = csvRows.join('\n')

  // Paso 5: Añadir el BOM (Byte Order Mark) para UTF-8
  const bom = '\uFEFF'

  // Paso 6: Crear un blob a partir del string CSV con BOM incluido
  const blob = new Blob([bom + csvString], { type: 'text/csv;charset=utf-8;' })

  // Paso 7: Crear un enlace de descarga
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  const date = dayjs().format('DD/MM/YYYY_HH:mm')
  a.download = fileName + '_' + date + '.csv'
  document.body.appendChild(a)
  a.click()

  // Paso 8: Limpiar el DOM
  document.body.removeChild(a)
  window.URL.revokeObjectURL(url)
}
