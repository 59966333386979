<template>
  <v-container id="incendioSelector" :class="{'mobile': $vuetify.breakpoint.xs}">
    <v-row dense>
      <v-col>
        <v-select
          v-model="selectedIncendio"
          :items="incendiosOrder"
          item-text="nombreIncendio"
          item-value="ID_INCENDIO"
          label="Selecciona un incendio"
          outlined
          dense
          @input="onSelectCoordinates"
        >
          <template #prepend-item>
            <v-list-item ripple @click="clearSelection">
              <v-list-item-content>
                <v-list-item-title>Seleccionar un incendio</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    selectedIncendio: null
  }),

  computed: {
    ...mapGetters('incendio', ['incendios']),

    incendiosOrder () {
      return this.orderIncendios()
    }
  },

  methods: {
    // Ordena los incendios según el estado y la fecha
    orderIncendios () {
      let orderIncendios = JSON.parse(JSON.stringify(this.incendios))
      orderIncendios.sort((a, b) => {
        let aEstado = a.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE
        let bEstado = b.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE
        let aFecha = a.FECHA_CREACION
        let bFecha = b.FECHA_CREACION

        if (aEstado === bEstado) {
          return (aFecha > bFecha) ? -1 : (aFecha < bFecha) ? 1 : 0
        } else {
          return (aEstado < bEstado) ? -1 : 1
        }
      })

      return orderIncendios.map(incendio => ({
        ...incendio,
        nombreIncendio: incendio.NOMBRE || incendio.LOCALIDAD,
        latitudIncendio: incendio.LATITUD,
        longitudIncendio: incendio.LONGITUD
      }))
    },

    onSelectCoordinates () {
      const selected = this.incendiosOrder.find(
        incendio => incendio.ID_INCENDIO === this.selectedIncendio
      )

      if (selected) {
        const { latitudIncendio, longitudIncendio } = selected
        this.$emit('selectCoordinates', latitudIncendio, longitudIncendio)
      } else {
        console.warn('No se encontró el incendio seleccionado.')
      }
    },
    clearSelection () {
      this.selectedIncendio = null
      this.$emit('selectCoordinates', null, null)
    }

  }
}
</script>
