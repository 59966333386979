<template>
  <div style="overflow-x: hidden !important;">
    <v-data-table :headers="headers" :items="mediosOrdered" disable-pagination hide-default-footer auto-resize>
      <template #no-data>
        <p>No hay medios asignados.</p>
      </template>

      <template #item="{ item }">
        <tr @dblclick="zoomMedio(item.ID_MEDIO)" :style="rowColor(item)">
          <td style="text-overflow: ellipsis;max-width: 150px;white-space: nowrap;overflow: hidden;">
            {{ item.MEDIO }} <span v-show="isMedioFueraHorario(item.ID_MEDIO)" class="font-weight-black">*</span>
          </td>

          <td>
            {{ item.FECHA_AVISO | formatDate }}
          </td>

          <td>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" :class="{ 'underline': item.FECHA_LLEGADA_GEOFENCING }">{{ item.FECHA_LLEGADA | formatDate }}</span>
              </template>
              <span v-if="item.FECHA_LLEGADA_GEOFENCING">Geofencing: {{ item.FECHA_LLEGADA_GEOFENCING | formatDate }}</span>
              <span v-else>Geofencing: no hay</span>
            </v-tooltip>
          </td>

          <td>
            {{ item.FECHA_SALIDA | formatDate }}
          </td>

          <td>
            {{ item.FECHA_LLEGADA_BASE | formatDate }}
          </td>

          <td>
            <v-layout>
              <!-- Tiempo -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn fab icon small v-bind="attrs" v-on="on" v-show="!item.FECHA_FIN_DESCANSO" :disabled="isClockButtonDisabled(item)" @click="setFechas(item)">
                    <v-icon color="black"> {{ !item.FECHA_LLEGADA_BASE ? 'mdi-clock-outline' : 'mdi-sleep' }}</v-icon>
                  </v-btn>
                </template>
                <span v-if="!item.FECHA_LLEGADA">Asignar llegada</span>
                <span v-if="item.FECHA_LLEGADA && !item.FECHA_SALIDA">Asignar salida</span>
                <span v-if="item.FECHA_SALIDA && !item.FECHA_LLEGADA_BASE">Asignar llegada base</span>
                <span v-if="item.FECHA_LLEGADA_BASE && !item.FECHA_FIN_DESCANSO">Asignar descanso</span>
              </v-tooltip>

              <!-- Editar -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn fab icon small v-bind="attrs" v-on="on" @click="editMedio(item)" :disabled="disabledByRol">
                    <v-icon color="black">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar medio</span>
              </v-tooltip>

              <!-- Editar -->
              <v-tooltip bottom v-if="item.LATITUD && item.LONGITUD">
                <template #activator="{ on, attrs }">
                  <v-btn fab icon small v-bind="attrs" v-on="on" @click="showRuta(item)" :disabled="disabledByRol">
                    <v-icon color="black">mdi-directions</v-icon>
                  </v-btn>
                </template>
                <span>Mostrar ruta</span>
              </v-tooltip>
            </v-layout>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="showDialogDescanso" persistent max-width="400">
      <v-card>
        <v-toolbar color="primaryLight" dark dense>
          <v-toolbar-title>Establecer descanso del medio</v-toolbar-title>
        </v-toolbar>

        <v-card-text class="pt-8">
          <v-form v-model="isValid">
            <v-container>
              <v-row align="center" justify="center" no-gutters>
                <v-col>
                  <v-slider v-model="horasDescanso" :min="2" :max="24" thumb-label="always" ticks="always" label="Horas descanso" />
                </v-col>
              </v-row>
              <v-row align="center" justify="center" no-gutters>
                <v-col>
                  <v-text-field v-model="fechaFinDescanso" label="Fecha fin de descanso" prepend-icon="esri-icon-calendar" outlined readonly hide-details />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text color="red" @click="showDialogDescanso = false">Cancelar</v-btn>
          <v-btn text color="green" @click="setFechaFinDescanso">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import constants from '../../../helpers/constants'
import * as ArcGIS from '../../../helpers/ArcGIS'

export default {
  props: {
    medios: Array
  },

  data: () => ({
    headers: [
      { text: 'Medio', value: 'MEDIO', align: 'left', sortable: true, width: '35%' },
      { text: 'Aviso', value: 'FECHA_AVISO', align: 'center', sortable: true },
      { text: 'Llegada', value: 'FECHA_LLEGADA', align: 'center', sortable: true },
      { text: 'Salida', value: 'FECHA_SALIDA', align: 'center', sortable: true },
      { text: 'Base', value: 'FECHA_LLEGADA_BASE', align: 'center', sortable: true },
      { text: '', value: 'ACCIONES', align: 'center', sortable: false }
    ],

    estadoMedios: [],
    showDialogDescanso: false,
    medioSelected: {},
    isValid: false,
    horasDescanso: 10
  }),

  computed: {
    allMedios () {
      return this.$store.getters['medio/medios']
    },

    fechaFinDescanso () {
      let finDescanso = null
      if (this.medioSelected) {
        let llegadaBase = this.$date.parseDate(this.medioSelected.FECHA_LLEGADA_BASE)
        finDescanso = llegadaBase.add(this.horasDescanso, 'hours').format('DD/MM/YYYY HH:mm')
      }
      return finDescanso
    },

    mediosOrdered () {
      let medios = []

      if (this.medios) {
        medios = this.orderMedios()
      }

      return medios
    },

    disabledByRol () {
      // Deshabilita funciones dependiendo del rol
      return !this.$store.getters['usuario/isCurrentUsuarioAdmin']
    }
  },

  methods: {
    rowColor (item) { // TODO: esto hay que rehacerlo
      let backgroundColor = '#CCC8C5'
      let borderColor = '#CCC8C5'

      // console.log(item)

      if (!item.FECHA_LLEGADA) {
        backgroundColor = this.estadoMedios[1].COLOR // Azul
        borderColor = this.estadoMedios[1].COLOR
      } else if (!item.FECHA_SALIDA) {
        backgroundColor = this.estadoMedios[2].COLOR // Rojo
        borderColor = this.estadoMedios[2].COLOR
      } else if (!item.FECHA_LLEGADA_BASE) {
        backgroundColor = this.estadoMedios[3].COLOR // Amarillo
        borderColor = this.estadoMedios[3].COLOR
      }

      return {
        'background-color': backgroundColor,
        'border-color': borderColor
      }
    },

    isMedioFueraHorario (idMedio) {
      let isMedioFueraHorario = false

      let medio = this.allMedios.find(x => x.ID_MEDIO === idMedio)
      if (medio) {
        let horaSalidaMedio = this.$date.parseDate(medio.HORA_SALIDA, 'HH:mm').format('HH:mm')
        let horaActual = this.$date.formatDate(this.$date.now(), 'HH:mm')
        isMedioFueraHorario = horaActual > horaSalidaMedio
      }
      return isMedioFueraHorario
    },

    orderMedios () {
      let order = JSON.parse(JSON.stringify(this.medios))

      let mediosOrder = order.sort((a, b) => {
        let aEstado = a.ESTADO
        let bEstado = b.ESTADO
        let aMedio = a.MEDIO.toUpperCase()
        let bMedio = b.MEDIO.toUpperCase()

        if (aEstado === bEstado) {
          return (aMedio < bMedio) ? -1 : (aMedio > bMedio) ? 1 : 0
        } else {
          return (aEstado < bEstado) ? -1 : 1
        }
      })

      return mediosOrder
    },

    editMedio (medio) {
      this.$emit('editMedioIncendio', medio)
    },

    showRuta (medio) {
      this.$emit('showRuta', medio.LATITUD, medio.LONGITUD)
    },

    isClockButtonDisabled (medio) {
      let now = this.$date.now()
      let fechaAviso = this.$date.parseDate(medio.FECHA_AVISO)
      let fechaLlegada = this.$date.parseDate(medio.FECHA_LLEGADA)
      let fechaSalida = this.$date.parseDate(medio.FECHA_SALIDA)
      return fechaAviso > now || fechaLlegada > now || fechaSalida > now
    },

    // Click btn reloj
    setFechas (medio) {
      let now = this.$date.now()

      if (!medio.FECHA_LLEGADA) {
        medio.FECHA_LLEGADA = now
      } else if (!medio.FECHA_SALIDA) {
        medio.FECHA_SALIDA = now
      } else if (!medio.FECHA_LLEGADA_BASE) {
        medio.FECHA_LLEGADA_BASE = now
      } else {
        this.showDialogDescanso = true
        this.medioSelected = medio
      }

      if (!this.showDialogDescanso) {
        this.$emit('setFecha', medio)
      }
    },

    setFechaFinDescanso () {
      this.showDialogDescanso = false
      this.medioSelected.FECHA_FIN_DESCANSO = this.$date.parseDate(this.fechaFinDescanso)
      this.$emit('setFecha', this.medioSelected)
    },

    zoomMedio (idMedio) {
      let medio = this.allMedios.find(x => x.ID_MEDIO === idMedio)
      if (medio && medio.ULT_POSICION) {
        let center = {
          target: [medio.ULT_POSICION.LONGITUD, medio.ULT_POSICION.LATITUD],
          zoom: 17
        }

        ArcGIS.setCenterMap(center)
      }
    }
  },

  created () {
    this.estadoMedios = constants.estadosMedio
  }
}
</script>

<style scoped>
</style>
