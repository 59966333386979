<template>
  <v-expansion-panels multiple flat popout>
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{ !items.id ? items.title : "" }}
        <v-container class="bg-surface-variant" v-if="items.id">
          <v-row :class="{ 'd-flex': isLargeScreen, 'flex-column': !isLargeScreen }">
            <v-col :class="{ 'col-fixed-width': isLargeScreen }" class="d-flex align-center">
              <v-switch
                v-if="items.id"
                hide-details
                :label="items.title"
                @click.native="$event.cancelBubble = true"
                v-model="visible"
              />
            </v-col>

            <v-col class="d-flex align-center">
              <v-slider
                v-if="items.id"
                v-model="opacity"
                label="Opacidad"
                min="10"
                max="100"
                step="10"
                hide-details
                @click.native="$event.cancelBubble = true"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-list dense v-if="items.sublayers && items.sublayers.length > 0">
          <vx-layer-item
            :item="itemSub" v-for="(itemSub, i) in items.sublayers" :key="i"
            @setVisible="setVisible"
          />
        </v-list>
        <p v-else>No hay sublayers disponibles.</p>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapActions } from 'vuex'

import LayerItem from './LayerItem.vue'
import * as ArcGIS from '@/helpers/ArcGIS'

export default {
  components: {
    'vx-layer-item': LayerItem
  },

  props: {
    items: {
      type: Object,
      default: () => ({})
    },
    layersExpansion: Array
  },

  data: () => ({
    visible: false,
    opacity: 70,
    item: null
  }),

  computed: {
    isLargeScreen () {
      return this.items.largeScreen !== undefined ? this.items.largeScreen : false
    }
  },

  watch: {
    visible () {
      if (this.items.sublayers.length !== 0) {
        this.setVisibilityLayer({
          id: this.items.id,
          visible: this.visible
        })
      } else {
        this.setVisibilityLayer({
          id: this.items.id,
          visible: this.visible,
          isGroup: false
        })
      }
    },

    opacity () {
      this.setOpacityLayer({
        id: this.items.id,
        opacity: this.opacity / 100
      })
      ArcGIS.setOpacityLayer(this.item.id, this.opacity / 100)
    }
  },

  methods: {

    ...mapActions('map', [
      'setVisibilityLayer',
      'setOpacityLayer'
    ]),

    setVisible () {
      this.visible = true
    }
  },

  // mounted () {
  //   // Ensure this.$refs.expansionPanel is defined
  //   this.$nextTick(() => {
  //     if (this.$refs.expansionPanel) {
  //       this.resizeObserver = new ResizeObserver(entries => {
  //         for (let entry of entries) {
  //           const width = entry.contentRect.width
  //           this.isLargeScreen = width > 700
  //         }
  //       })
  //       this.resizeObserver.observe(this.$refs.expansionPanel.$el)
  //     }
  //   })
  // },

  // beforeDestroy () {
  //   if (this.resizeObserver) {
  //     this.resizeObserver.disconnect()
  //   }
  // },

  created () {
    if (this.items.id) {
      this.visible = this.items.visible

      if (this.items.opacity) {
        this.opacity = this.items.opacity * 100
      }
    }
  }
}
</script>

  <style scoped>
    #layerList {
      z-index: 3;
      overflow-x: hidden;
      position: absolute;
      top: 0px;
      right: 0px;
    }

    .layerItem {
      background: #ebebeb;
      border-radius: 10px;
    }

    .col-fixed-width {
      max-width: 200px; /* Puedes ajustar este valor según tus necesidades */
    }

    @media (min-width: 350px) {
      .bg-surface-variant .v-row {
        flex-direction: row;
      }
    }

  </style>
