import request from '../request'

export default {
  calcularRuta: (data) => {
    return request.post('route', data)
  },

  calcularIsochrone: (data) => {
    return request.post('isochrone', data)
  }
}
