<template>
  <v-dialog v-model="isOpen" persistent max-width="450px">
    <v-card>
      <v-card-title class="px-0 pt-0">
        <v-toolbar color="primaryLight" dark dense>
          <v-toolbar-title>Introducir coordenadas</v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-container class="mt-2">
          <v-row>
            <vx-incendios-selector @selectCoordinates="handleSelectCoordinates" />
          </v-row>

          <!-- LATITUD LONGITUD -->
          <div>
            <v-row dense v-if="!medio">
              <v-col cols="3" class="pt-3">
                <label class="font-weight-bold ">
                  Inicio
                  <!-- <v-icon @click="showMap('init')">mdi-map-marker</v-icon> -->
                </label>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="ptInit.latitude" label="Lat" outlined dense required maxlength="8" />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="ptInit.longitude" label="Long" outlined dense required maxlength="8" />
              </v-col>
              <v-col cols="1" class="pt-3"><v-icon @click="showMap('init')">mdi-map-marker</v-icon></v-col>
            </v-row>
            <v-row dense>
              <v-col cols="3">
                <label class="font-weight-bold label-container">
                  Destino
                </label>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="ptEnd.latitude" label="Lat" outlined dense required maxlength="8" />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="ptEnd.longitude" label="Long" outlined dense required maxlength="8" />
              </v-col>
              <v-col cols="1" class="pt-3"><v-icon @click="showMap('end')">mdi-map-marker</v-icon></v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cerrar">Cerrar</v-btn>
        <!-- Aqui debería calcular  -->
        <v-btn text color="primary" @click="calcular" :disabled="!isValid">Calcular</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/api'
import * as ArcGIS from '@/helpers/ArcGIS.js'
import IncendioSelector from './IncendioSelector.vue'
export default {
  props: {
    show: Boolean,
    medio: Object
  },
  components: {
    'vx-incendios-selector': IncendioSelector
  },
  data: () => ({
    tipoCoordenadas: 'Latitud / Longitud', // TODOO: CAMBIAR PARA INCENDIO
    coordenadas: {},
    ptInit: {
      latitude: null,
      longitude: null
    },
    ptEnd: {
      latitude: null,
      longitude: null
    },
    showIncendios: false,
    isOpen: false,
    coordSelected: '',
    isToastVisible: false

  }),

  methods: {
    handleClick () {
      // Emitir un evento al padre
      this.$emit('openToast')
    },
    isValid () {
      // Validar si todos los campos requeridos tienen valores válidos
      return (
        this.ptInit.latitude &&
        this.ptInit.longitude &&
        this.ptEnd.latitude &&
        this.ptEnd.longitude
      )
    },
    resetVariables () {
      this.tipoCoordenadas = 'Latitud / Longitud'
      this.ptInit = { latitude: null, longitude: null }
      this.ptEnd = { latitude: null, longitude: null }
    },
    isCoordinateValid (value) {
      const regex = /^-?\d{1,2}(\.\d+)?$/
      return regex.test(value)
    },

    areCoordinatesValid (locations) {
      return locations.every(({ lat, lon }) =>
        this.isCoordinateValid(lat) && this.isCoordinateValid(lon)
      )
    },

    notify ({ title, text, type }) {
      this.$notify({ title, text, type })
    },

    handleSelectCoordinates (lat, long) {
      if (lat && long) {
        this.ptEnd.latitude = lat
        this.ptEnd.longitude = long
      } else {
        this.ptEnd.latitude = null
        this.ptEnd.longitude = null
      }
    },
    showMap (choose) {
      this.isOpen = false
      ArcGIS.clearSimulacionSketch()
      this.coordSelected = choose

      let data = {
        categoria: 'foco',
        event: 'point',
        callback: this.callbackGeometryDone
      }
      ArcGIS.initSimulacionSketchViewModel(data)
    },
    callbackGeometryDone (geometry) {
      let latLon = ArcGIS.convertirETRS89ToLatLon(
        geometry.coordinates[0],
        geometry.coordinates[1]
      )
      // Limitamos el número de decimales
      if (this.coordSelected === 'init') {
        this.ptInit.latitude = parseFloat(latLon[1].toString().substring(0, 8))
        this.ptInit.longitude = parseFloat(latLon[0].toString().substring(0, 8))
      } else if (this.coordSelected === 'end') {
        this.ptEnd.latitude = parseFloat(latLon[1].toString().substring(0, 8))
        this.ptEnd.longitude = parseFloat(latLon[0].toString().substring(0, 8))
      }
      this.isOpen = true
    },
    async calcular () {
      const result = {
        locations: [
          { lat: parseFloat(this.ptInit.latitude), lon: parseFloat(this.ptInit.longitude) },
          { lat: parseFloat(this.ptEnd.latitude), lon: parseFloat(this.ptEnd.longitude) }
        ]
      }

      if (!this.areCoordinatesValid(result.locations)) {
        this.notify({
          title: 'Error en el formato de las coordenadas',
          text: 'La coordenada debe tener el formato: DD o DD.DD',
          type: 'error'
        })
        return
      }

      try {
        const dataResponse = await api.ruta.calcularRuta(result)

        ArcGIS.showRutasGeoJSON(dataResponse.data.geoJSON)
        this.isOpen = false

        this.notify({
          title: 'Ruta calculada',
          text: 'La ruta se ha calculado correctamente',
          type: 'success'
        })

        let showToast = {
          distance: dataResponse.data.distance,
          time: dataResponse.data.duration
        }

        this.cerrar(showToast)
      } catch (error) {
        console.error('Error al calcular la ruta:', error)
        this.notify({
          title: 'Error en la ruta',
          text: 'Revise los datos ingresados',
          type: 'error'
        })
      }
    },

    cerrar (showToast) {
      this.resetVariables()
      this.isOpen = false
      if (showToast.distance && showToast.time) this.$emit('cerrar', showToast)
      else this.$emit('cerrar', null)

      ArcGIS.clearSimulacionSketch()
    },
    async setNewCoordenadasMap () {
      if (this.medio) {
        this.ptInit.latitude = this.medio.ULT_POSICION.LATITUD
        this.ptInit.longitude = this.medio.ULT_POSICION.LONGITUD
      }
    }
  },
  mounted () {
    // this.$eventHub.$on('coordenadasMap', this.setNewCoordenadasMap)
    this.setNewCoordenadasMap()
  },
  watch: {
    show (newVal) {
      if (newVal) {
        this.isOpen = newVal
        this.setNewCoordenadasMap()
      }
    }
  }
}
</script>
<style scoped>
.label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
