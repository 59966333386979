<template>
  <v-dialog v-model="show" persistent width="700px">
    <v-card flat>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Imágenes</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row align="center" justify="center" class="mt-3">
          <v-file-input
            v-model="imagenes"
            multiple
            label="Introduzca las imágenes"
            variant="filled"
            prepend-icon="mdi-camera"
            accept="image/png, image/jpeg, image/bmp"
          />
          <v-btn text color="green" @click="aceptar" :disabled="imagenes.length === 0">Subir</v-btn>
        </v-row>
        <v-container style="overflow-y: auto; height: 55vh;">
          <v-row v-if="urls.length > 0">
            <v-col

              v-for="(url,i) in urls"
              :key="i"
              class="d-flex "
              cols="4"
            >
              <v-img
                @click="ampliarImagen(i)"
                aspect-ratio="1"
                :src="url"
                class="grey lighten-2"
                style="cursor: pointer;"
              >
                <template #placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    />
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col align="center">
              <p class="subtitle-1 black--text">No hay imágenes disponibles.</p>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="red" @click="cancelar">Salir</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showBigImagen" max-width="800px">
      <v-card class="pt-5" align="center">
        <v-card-text>
          <v-container style="overflow-y: auto; max-height: 75vh;">
            <v-img
              :src="urlBig"
              max-height="700"
              max-width="800"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="red" @click="deleteImagen(indexBig)">Eliminar</v-btn>
          <v-btn text color="blue" @click="descargarImagen(indexBig)">Descargar</v-btn>
          <v-btn text color="blue" @click="showBigImagen = false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>

import api from '@/api'

export default {
  props: {
    show: Boolean,
    idInfraestructura: String
  },

  data: () => ({
    isValid: false,

    urls: [],

    imagenes: [],

    imagenesOld: [],

    showBigImagen: false,

    urlBig: null,

    indexBig: null
  }),

  computed: {

  },

  watch: {
    show () {
      if (this.show) { this.fetchImagenes() }
    }
  },

  methods: {

    cancelar () {
      this.$emit('cancelar')
      this.urls = []
      this.imagenes = []
    },

    async aceptar () {
      let imagenesSend = []

      for (let i = 0; i < this.imagenes.length; i++) {
        // this.urls.push(URL.createObjectURL(this.imagenes[i]))

        imagenesSend.push(await new Promise((resolve, reject) => {
          const reader = new FileReader()

          // set the callbacks before reading the object
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)

          reader.readAsDataURL(this.imagenes[i])
        }))
      }

      let data = {
        ID_INFRAESTRUCTURA: this.idInfraestructura,
        imagenes: imagenesSend
      }

      await api.imagenInfraestructura.setImagenesInfraestructura(data)

      this.urls = []
      this.imagenes = []

      this.fetchImagenes()
    },

    async fetchImagenes () {
      this.imagenesOld = (await api.imagenInfraestructura.getImagenesInfraestructura(this.idInfraestructura)).data

      for (let i = 0; i < this.imagenesOld.length; i++) {
        let binary = ''
        let bytes = new Uint8Array(this.imagenesOld[i].IMAGEN.data)
        let len = bytes.byteLength
        for (let j = 0; j < len; j++) {
          binary += String.fromCharCode(bytes[j])
        }

        this.urls.push('data:image/jpeg;base64,' + window.btoa(binary))
      }
    },

    ampliarImagen (i) {
      this.urlBig = this.urls[i]
      this.indexBig = i
      this.showBigImagen = true
    },

    deleteImagen (index) {
      let data = {
        ID_INFRAESTRUCTURA: this.idInfraestructura,
        ID_INFRAESTRUCTURA_IMAGEN: this.imagenesOld[index].ID_INFRAESTRUCTURA_IMAGEN
      }

      api.imagenInfraestructura.deleteImagenInfraestructura(data)

      this.imagenesOld.splice(index, 1)
      this.urls.splice(index, 1)
      this.showBigImagen = false
    },

    descargarImagen (index) {
      let downloader = document.createElement('a') // Creamos el componente que descargue
      downloader.href = this.urls[index] // Image Base64
      downloader.download = 'Imagen_infraestructura.png' // Nombre de la imagen
      downloader.click() // Descarga el archivo
    }

  }
}
</script>

  <style scoped>
  </style>
