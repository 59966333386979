import request from './request'
import auth from './auth'

import actuaciones from './actuaciones'
import imagenSatelite from './imagenSatelite'
import imagenIncendio from './imagenIncendio'
import incendio from './incendio'
import medio from './medio'
import parametro from './parametro'
import planificacion from './planificacion'
import proxy from './proxy'
import simulacion from './simulacion'
import navarra from './navarra'
import camara from './camara'
import organismo from './organismo'
import infraestructura from './infraestructura'
import imagenInfraestructura from './imagenInfraestructura'
import demarcacion from './demarcacion'

import others from './others'
import ruta from './ruta'

export default {
  request,

  auth,

  actuaciones,
  imagenIncendio,
  imagenSatelite,
  incendio,
  medio,
  parametro,
  planificacion,
  proxy,
  simulacion,
  camara,
  ruta,

  infraestructura,
  organismo,
  imagenInfraestructura,
  demarcacion,

  navarra,

  others
}
