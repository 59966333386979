<template>
  <v-snackbar v-model="isDrawing" bottom :timeout="-1">
    {{ mensaje }}
    <template #action="{ attrs }">
      <v-btn color="pink" v-bind="attrs" @click="cancelDrawingPolygon">
        Cancelar
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {

  data: () => ({
    isDrawing: false,
    mensaje: null
  }),
  methods: {
    cancelDrawingPolygon () {
      this.isDrawing = false
      this.$eventHub.$emit('cancelDraw')
    },
    endSnack () {
      this.$destroy()
      this.$el.remove()
    },
    initSnackBar (text) {
      this.mensaje = text
      this.isDrawing = true
    }

  },
  // watch: {
  //   DrawMap () {
  //     this.isDrawing = true
  //   }
  // },
  mounted () {
    this.$eventHub.$on('DrawMap', this.initSnackBar)
    this.$eventHub.$on('EndDraw', this.endSnack)
  }
  // beforeDestroy () {
  //   this.$ventHub.$off('EndMap', this.endSnack)
  // }
}

</script>
