<template>
  <div v-show="show">
    <VueDragResize :w="'auto'" :h="'auto'" :isResizable="false" @dragging="resize" :aspectRatio="true" style="background-color: white;">
      <!--:w='1024' :h='500':w='1024' :h='576'-->
      <!-- <v-dialog v-model="show" persistent> -->
      <div class="main">
        <!-- <div class="left" v-if="!isPTZ">
          <button @click="getCameras">Cámaras Disponibles</button>
          {{ cameraList.length }}
          <v-btn text v-for="c, index in cameraList" :key="c.name" @click="viewCamera(c)" class="text--primary">
            Cámara {{ index + 1 }}
          </v-btn>
        </div> -->

        <div class="upperBar">
          {{ camara ? camara.NOMBRE : 'Error camara' }}
          <!-- <v-spacer /> -->
          <v-icon color="error" class="mr-0 cerrarBtn" @click="cerrar">mdi-close</v-icon>
        </div>
        <div class="container" v-if="showImage">
          <div @mousemove="capturarCoordenadasOnMove">
            <img id="image" class="img" ref="imageLive">
            <!--ref="imageLive"-->
            <div v-if="isPTZ">
              <v-btn
                v-for="(button, index) in buttons"
                :key="index"
                icon
                :class="button.class"
                @click="movePTZ(button)"
              >
                <v-icon>{{ button.icon }}</v-icon>
              </v-btn>
            <!-- <v-row no-gutters>
                <v-col v-for="(image, index) in imagenesOrdenadas" :key="index" style="flex-grow: 0;">
                  <v-img :src="image.src" width="100" />
                </v-col>
              </v-row> -->
            </div>
          </div>
        </div>
      </div>
    <!-- </v-dialog> -->
    </VueDragResize>
  </div>
</template>

<script>
import VueDragResize from 'vue-drag-resize'
import { ref } from 'vue' // Cambiar por Import Vue from vue
import { XPMobileSDK, initialize } from '../../lib/XPMobileSDK/index'
import * as ArcGIS from '../../helpers/ArcGIS'
import mockImagen from '../../assets/login_background.jpg'
import api from '../../api'
export default {
  setup () {
    const imageLive = ref() // Cambiar por Vue.ref()

    return {
      imageLive
    }
  },
  components: {
    VueDragResize
  },
  props: {
  },
  data: () => ({
    mockImagenPath: mockImagen,
    camara: {},
    show: false,
    isLoged: false,
    isPTZ: false,
    showImage: false,
    initHeight: 1200,
    initWidth: 900,
    height: 0,
    left: 0,
    top: 169,
    width: 884,
    cameraList: [],
    cameraSelected: null,
    videoController: null,
    imageURL: null,
    images: [],
    // numImgMosaico: 9,
    buttons: [
      { icon: 'mdi-arrow-left', action: 'Left', class: 'button-left' },
      { icon: 'mdi-arrow-up', action: 'Up', class: 'button-up' },
      { icon: 'mdi-arrow-down', action: 'Down', class: 'button-down' },
      { icon: 'mdi-arrow-right', action: 'Right', class: 'button-right' },
      { icon: 'mdi-plus', action: 'ZoomIn', class: 'button-zoomIn' },
      { icon: 'mdi-minus', action: 'ZoomOut', class: 'button-zoomOut' }

      // Agrega más botones según sea necesario
    ],
    coordenadas: {
      x: 0,
      y: 0
    }
  }),

  watch: {
    show () {
    }
  },

  computed: {
    imagenesOrdenadas () {
      return this.images.slice().sort((a, b) => a.time - b.time)
    }
  },
  methods: {
    async cerrar () {
      this.show = false

      this.showImage = false
      this.images = this.images.map(objeto => ({ ...objeto, used: false }))
      ArcGIS.clearCamarasSketch()
      await this.$store.dispatch('camara/setIsCamaraOpen', false)
      // if (this.cameraSelected) XPMobileSDK.closeStream(this.videoController.videoId)
    },
    async abrir (cam) {
      // console.log('Parametros camara: ', cam)
      // Comentar cuando funcionen las camaras
      // this.show = true
      // this.showImage = true
      // this.camara = cam
      // await this.$store.dispatch('camara/setIsCamaraOpen', true)

      // Fin comentar
      if (this.isLoged) {
        this.camara = cam
        this.getCameras(cam.ID_CAMARA)
        await this.$store.dispatch('camara/setIsCamaraOpen', true)
      } else {
        // initialize(this.callbackLogin)
        this.$swal({
          toast: true,
          position: 'top',
          title: 'WARNING',
          icon: 'warning',
          text: 'No estan disponibles por el momento. Refresque la pestaña si persiste.',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 4000
        })
      }
    },
    resize (newRect) {
      // console.log(newRect)
      this.width = newRect.width
      this.height = newRect.height
      this.top = newRect.top
      this.left = newRect.left
    },
    getCameras (idCamara) {
      XPMobileSDK.library.Connection.webSocketBrowser = false

      XPMobileSDK.getAllViews(async (items) => {
        // console.log('Items all views: ', items)
        if (items && items.length) {
          const root = items[0]

          if (root.Items.length) {
            const server = root.Items[0]

            const folders = server.Items

            if (folders.length) {
              // console.log(folders, idCamara)
              const cameras = folders
                .flatMap((x) => x.Items)
                .filter((x) => x.Type === 'Camera' && x.Id === idCamara) // && x.PTZ !== 'No'
              this.cameraList = cameras
              // console.log('Lista Camera: ', cameras)

              if (cameras.length === 0) {
                await this.$store.dispatch('camara/setIsCamaraOpen', true)

                this.$swal({
                  toast: true,
                  position: 'top',
                  title: 'WARNING',
                  icon: 'warning',
                  text: 'Cámara no disponible.',
                  showCancelButton: false,
                  showConfirmButton: false,
                  timer: 4000
                })
                this.show = false
                return
              }

              if (this.cameraList[0].Items.PTZ !== 'No') {
                this.isPTZ = true
              }

              if (this.cameraList[0].PTZ === 'No') {
                this.isPTZ = false
              }

              this.showImage = true

              this.viewCamera(this.cameraList[0])
              // console.log('------------', this.cameraList[0])
            }
          }
        }
      })
    },
    onVideoFrameReceived (frame) {
      if (frame.dataSize > 0 && this.imageLive) {
        if (frame.hasSizeInformation) {
          let multiplier =
            frame.sizeInfo.destinationSize.resampling *
              XPMobileSDK.getResamplingFactor() || 1
          // console.log('Multiplier1: ', frame.sizeInfo.destinationSize)
          // console.log('Multiplier2: ', XPMobileSDK.getResamplingFactor())
          this.imageLive.width = multiplier * frame.sizeInfo.destinationSize.width
          this.imageLive.height = multiplier * frame.sizeInfo.destinationSize.height
        }

        // if (this.imageLive.height !== this.initHeight) {
        //   // console.log('change height')
        //   this.initHeight = this.imageLive.height
        // }

        // if (this.imageLive.width !== this.initWidth) {
        //   // console.log('change width')
        //   this.initWidth = this.imageLive.width
        // }

        if (this.imageURL) {
          window.URL.revokeObjectURL(this.imageURL)
        }

        this.imageURL = window.URL.createObjectURL(frame.blob)

        this.imageLive.src = this.imageURL
      }
    },
    requestStreamCallback (videoConnection) {
      this.videoController = videoConnection

      videoConnection.addObserver({
        videoConnectionReceivedFrame: this.onVideoFrameReceived
      })
      videoConnection.cameraId = this.cameraSelected.Id

      videoConnection.open()
    },
    getThumbnailCallback (thumbnail, timestamp) {
      return new Promise(resolve => {
        const imageBlob = this.b64toBlob(thumbnail, 'image/jpeg', 512)

        let imageURL = window.URL.createObjectURL(imageBlob)

        this.images.push({ src: imageURL, time: timestamp })

        resolve()
      })
    },
    b64toBlob (b64Data, contentType, sliceSize) {
      const byteCharacters = atob(b64Data)
      const byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }

      const blob = new Blob(byteArrays, { type: contentType })
      return blob
    },
    async viewCamera (camera) {
      // This is the time you request from the server
      // You can set it to any valid timestamp

      if (this.cameraSelected) XPMobileSDK.closeStream(this.videoController.videoId)

      this.show = true

      // this.images = []
      this.cameraSelected = camera

      const id = camera.Id

      const streamParams = {
        CameraId: id,
        DestWidth: 1920,
        DestHeight: 1080,
        SignalType: 'Live',
        MethodType: 'Push',
        Fps: 60, // This doesn't work for Pull mode, but we have to supply it anyway to keep the server happy
        ComprLevel: 71,
        KeyFramesOnly: 'No', // Server will give only key frame thumb nails. This will reduce FPS
        RequestSize: 'Yes',
        StreamType: 'Transcoded'
      }

      // var streamRequest =
      XPMobileSDK.RequestStream(
        streamParams,
        this.requestStreamCallback,
        this.getRequestErrorCallback
      )

      // for (let i = 0; i < this.numImgMosaico; i++) {
      //   let date = new Date().getTime() - i * 45000

      //   let params = {
      //     cameraId: camera.Id,
      //     width: 300,
      //     height: 125,
      //     time: date
      //   }
      //   await XPMobileSDK.getThumbnailByTime(params, this.getThumbnailCallback, this.getThumbnailErrorCallback)
      // }
    },
    getThumbnailErrorCallback () {
      console.error('Error request thumbnail.')
    },
    getRequestErrorCallback () {
      console.error('Error request video streaming.')
    },
    async movePTZ (button) {
      XPMobileSDK.ptzMove(this.videoController, button.action)
      const ptz = (await api.camara.getCamaraPTZ(this.camara.ID_CAMARA)).data // { Pan: 1, Zoom: 0, Tilt: 0 }
      if (ptz) {
        // console.log('Ptz que se pinta al mover: ', ptz)
        // console.log('Camara: ', this.camara)
        await ArcGIS.drawCameraConeFov([this.camara.LONGITUD, this.camara.LATITUD], ptz.Pan, ptz.Zoom, null)
      }
    },
    async capturarCoordenadasOnMove (event) {
      // ArcGIS.clearCamarasSketch()

      if (this.show && event) {
        this.coordenadas.x = event.offsetX
        this.coordenadas.y = event.offsetY
        // console.log('Camara: ', this.camara)
        const ptz = (await api.camara.getCamaraPTZ(this.camara.ID_CAMARA)).data // { Pan: 1, Zoom: 0, Tilt: 0 }
        const myImg = document.querySelector('#image')
        if (ptz && myImg) {
          this.imageSize = {
            alto: myImg.clientHeight,
            ancho: myImg.clientWidth
          }
          // console.log('Ptz que se pinta al mover: ', ptz)
          // console.log('Camara: ', this.camara)
          await ArcGIS.drawCameraConeFov([this.camara.LONGITUD, this.camara.LATITUD], ptz.Pan, ptz.Zoom, { coords: this.coordenadas, alto: this.imageSize.alto, ancho: this.imageSize.ancho })
        }
      }
    },
    callbackLogin () {
      // console.log('Loguiao')
      this.isLoged = true
    },
    async moveCameraToPoint (pixel) {
      // console.log(this.camara.ID_CAMARA)
      const coords = ArcGIS.convertirLatLonToETRS89(pixel[0], pixel[1])
      // console.log('Pan : ', this.calcularPanNormalizado(pixel, [this.camara.x, this.camara.y]))
      const pan = this.calcularPanNormalizado(coords, [this.camara.LONGITUD, this.camara.LATITUD])
      await this.$store.dispatch('camara/movePTZToPoint', { idCamara: this.camara.ID_CAMARA, pan: pan.toString() })
      // console.log('Pan que le doy: ', pan)
      if (this.show) { ArcGIS.drawCameraConeFov([this.camara.LONGITUD, this.camara.LATITUD], pan, 0, null) }
    },
    calcularPanNormalizado (puntoDestino, puntoOrigen) {
      // console.log('calcularPanNormalizado', puntoOrigen, puntoDestino)
      // Diferencia en las coordenadas
      let diffX = puntoDestino[0] - puntoOrigen[0]
      let diffY = puntoDestino[1] - puntoOrigen[1]

      // console.log('diffX', diffX)
      // console.log('diffY', diffY)
      // Calcular el ángulo en radianes
      let anguloRad = Math.atan2(diffX, diffY)

      // console.log('anguloRad', anguloRad)

      // Convertir a grados
      let anguloGrados = anguloRad * (180.0 / Math.PI)

      // Normalizar el ángulo
      let anguloNormalizado = anguloGrados / 180

      // Asegurarse de que el ángulo esté en el rango de -1 a 1
      // while (anguloNormalizado > 1) {
      //   anguloNormalizado -= 2
      // }
      // while (anguloNormalizado < -1) {
      //   anguloNormalizado += 2
      // }

      // console.log('anguloNormalizado', anguloNormalizado)
      return anguloNormalizado
    }

  },
  mounted: function () {
    this.$eventHub.$on('showCamara', this.abrir)
    this.$eventHub.$on('movePtzPoint', this.moveCameraToPoint)
    // initialize(this.callbackLogin)
  }
}
</script>

<style scoped>
.img{
  width: 100%;
  height: auto;
}

.upperBar {
  position: absolute;
  margin-top: 20px;
  margin-left: 20px;
}

.cerrarBtn {
  display: absolute;
  top: 0;
  right: 0;
}

.button-left {
  position: absolute;
  top: 50%;
  right: 90%;
  transform: translate(-50%, -50%);
  background-color: lightblue;

}

.button-right {
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translate(-50%, -50%);
  background-color: lightblue;

}

.button-up {
  position: absolute;
  bottom: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: lightblue;

}

.button-down {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: lightblue;
}

.image-container {
  width: 100%;
  padding: 0 !important;
}

/* div > img {
  display: block;
  position: inherit;
  height: 100%;
  width: auto;
} */
</style>
