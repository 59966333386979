var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"overflow-x":"hidden !important"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.mediosOrdered,"disable-pagination":"","hide-default-footer":"","auto-resize":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("No hay medios asignados.")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{style:(_vm.rowColor(item)),on:{"dblclick":function($event){return _vm.zoomMedio(item.ID_MEDIO)}}},[_c('td',{staticStyle:{"text-overflow":"ellipsis","max-width":"150px","white-space":"nowrap","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.MEDIO)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMedioFueraHorario(item.ID_MEDIO)),expression:"isMedioFueraHorario(item.ID_MEDIO)"}],staticClass:"font-weight-black"},[_vm._v("*")])]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.FECHA_AVISO))+" ")]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({class:{ 'underline': item.FECHA_LLEGADA_GEOFENCING }},'span',attrs,false),on),[_vm._v(_vm._s(_vm._f("formatDate")(item.FECHA_LLEGADA)))])]}}],null,true)},[(item.FECHA_LLEGADA_GEOFENCING)?_c('span',[_vm._v("Geofencing: "+_vm._s(_vm._f("formatDate")(item.FECHA_LLEGADA_GEOFENCING)))]):_c('span',[_vm._v("Geofencing: no hay")])])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.FECHA_SALIDA))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.FECHA_LLEGADA_BASE))+" ")]),_c('td',[_c('v-layout',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!item.FECHA_FIN_DESCANSO),expression:"!item.FECHA_FIN_DESCANSO"}],attrs:{"fab":"","icon":"","small":"","disabled":_vm.isClockButtonDisabled(item)},on:{"click":function($event){return _vm.setFechas(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" "+_vm._s(!item.FECHA_LLEGADA_BASE ? 'mdi-clock-outline' : 'mdi-sleep'))])],1)]}}],null,true)},[(!item.FECHA_LLEGADA)?_c('span',[_vm._v("Asignar llegada")]):_vm._e(),(item.FECHA_LLEGADA && !item.FECHA_SALIDA)?_c('span',[_vm._v("Asignar salida")]):_vm._e(),(item.FECHA_SALIDA && !item.FECHA_LLEGADA_BASE)?_c('span',[_vm._v("Asignar llegada base")]):_vm._e(),(item.FECHA_LLEGADA_BASE && !item.FECHA_FIN_DESCANSO)?_c('span',[_vm._v("Asignar descanso")]):_vm._e()]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","icon":"","small":"","disabled":_vm.disabledByRol},on:{"click":function($event){return _vm.editMedio(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar medio")])]),(item.LATITUD && item.LONGITUD)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","icon":"","small":"","disabled":_vm.disabledByRol},on:{"click":function($event){return _vm.showRuta(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-directions")])],1)]}}],null,true)},[_c('span',[_vm._v("Mostrar ruta")])]):_vm._e()],1)],1)])]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.showDialogDescanso),callback:function ($$v) {_vm.showDialogDescanso=$$v},expression:"showDialogDescanso"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primaryLight","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Establecer descanso del medio")])],1),_c('v-card-text',{staticClass:"pt-8"},[_c('v-form',{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-col',[_c('v-slider',{attrs:{"min":2,"max":24,"thumb-label":"always","ticks":"always","label":"Horas descanso"},model:{value:(_vm.horasDescanso),callback:function ($$v) {_vm.horasDescanso=$$v},expression:"horasDescanso"}})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Fecha fin de descanso","prepend-icon":"esri-icon-calendar","outlined":"","readonly":"","hide-details":""},model:{value:(_vm.fechaFinDescanso),callback:function ($$v) {_vm.fechaFinDescanso=$$v},expression:"fechaFinDescanso"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":function($event){_vm.showDialogDescanso = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"text":"","color":"green"},on:{"click":_vm.setFechaFinDescanso}},[_vm._v("Aceptar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }