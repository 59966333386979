<template>
  <v-dialog v-model="showDialog" width="400px" persistent>
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ isEdit ? 'Editar' : 'Crear' }} plan de operaciones</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="pt-4">
        <v-form v-model="isValid">
          <v-text-field v-model="nombre" id="nombre" name="nombre" label="Nombre" outlined :rules="[globalRules.required]" />
          <v-textarea v-model="descripcion" label="Descripción" outlined :rules="[globalRules.required]" />

          <vx-date-text-edit v-model="fecha" label="Fecha" outlined required />
        </v-form>

        <v-btn text color="primary" @click="drawGraphicsOnMap">
          {{ isEdit ? 'Editar' : 'Añadir' }} gráficos
          <v-icon>mdi-map-marker-star</v-icon>
        </v-btn>

        <v-container class="mt-4">
          <v-row>
            Gráficos:
          </v-row>
          <v-row>
            &nbsp;-Símbolos: {{ nSymbols }}
          </v-row>
          <v-row>
            &nbsp;-Polígonos: {{ nPolygons }}
          </v-row>
          <v-row>
            &nbsp;-Líneas: {{ nPolylines }}
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn text color="red" v-if="isEdit" @click="deletePlan">Borrar</v-btn>
        <v-spacer />
        <v-btn text color="red" @click="closeDialog">Cancelar</v-btn>
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import * as ArcGIS from '../../../helpers/ArcGIS'

export default {
  // PROPIEDADES QUE PUEDEN SER PASADAS AL COMPONENTE DESDE EL EXTERIOR
  props: {
    show: Boolean, // PARA CONTROLAR LA VISIBILIDAD DEL DIÁLOGO
    idIncendio: String, // EL ID DEL INCENDIO RELACIONADO
    planSelected: Object, // EL PLAN SELECCIONADO (OBJETO)
    isEdit: Boolean // BOOLEANO QUE INDICA SI ESTÁ EN MODO DE EDICIÓN
  },

  // ESTADOS INICIALES DEL COMPONENTE
  data: () => ({
    showDialog: false, // PARA CONTROLAR SI SE MUESTRA EL DIÁLOGO
    isValid: false,
    nombre: null,
    descripcion: null,
    fecha: null,
    graphicList: []
  }),

  // PROPIEDADES COMPUTADAS QUE DEVUELVEN NÚMEROS BASADOS EN graphicList
  computed: {
    nSymbols () { // CUANTOS GRÁFICOS NO SON POLÍGONOS NI LÍNEAS
      if (this.graphicList) {
        return this.graphicList.filter(x => x.type !== 'polygon' && x.type !== 'polyline').length
      } else return 0
    },

    nPolygons () { // CUANTOS GRÁFICOS SON POLÍGONOS
      if (this.graphicList) {
        return this.graphicList.filter(x => x.type === 'polygon').length
      } else return 0
    },

    nPolylines () { // CUANTOS GRÁFICOS SON LÍNEAS
      if (this.graphicList) {
        return this.graphicList.filter(x => x.type === 'polyline').length
      } else return 0
    }
  },

  // OBSERVADORES QUE REACCIONAN A CAMBIOS EN LAS PROPIEDADES
  watch: {
    show () { // REACCIONA A CAMBIOS EN LA PROPIEDAD show
      this.showDialog = this.show

      if (this.show) {
        if (this.isEdit) { // MODO DE EDICIÓN
          this.nombre = this.planSelected.NOMBRE
          this.descripcion = this.planSelected.DESCRIPCION
          this.fecha = this.$date.formatDate(this.planSelected.FECHA, 'DD/MM/YYYY HH:mm')

          this.graphicList = [...this.planSelected.GRAFICOS] // CLONAR LISTA DE GRÁFICOS
        } else {
          this.fecha = this.$date.currentDate() // ESTABLECER FECHA ACTUAL
        }
      }
    }
  },

  // MÉTODOS DEL COMPONENTE
  methods: {
    drawGraphicsOnMap () { // DIBUJAR GRÁFICOS EN EL MAPA
      this.showDialog = false

      this.$eventHub.$emit('showSymbology', { // EMITE UN EVENTO PARA DIBUJAR GRÁFICOS
        callback: this.onDrawGraphicsEnded,
        graphics: JSON.parse(JSON.stringify(this.graphicList)) // CLONAR LISTA
      })
    },

    onDrawGraphicsEnded (graphics) { // CUANDO SE TERMINA DE DIBUJAR GRÁFICOS
      this.showDialog = true
      this.graphicList = graphics // ASIGNAR GRÁFICOS DIBUJADOS A graphicList
    },

    aceptar () { // ACEPTAR CAMBIOS
      if (!this.isEdit) {
        this.addPlan()
      } else {
        this.editPlan()
      }
    },

    addPlan () { // AÑADIR UN NUEVO PLAN
      let date = this.$date.parseDate(this.fecha)
      let newPlan = {
        ID_PLAN_OPERACIONES: this.$uuid.createUUID(), // GENERAR UN ID ÚNICO
        ID_INCENDIO: this.idIncendio,
        NOMBRE: this.nombre,
        DESCRIPCION: this.descripcion,
        GRAFICOS: this.graphicList, // GRAFICOS VIENE DE INCENDIO ->PLAN_OPERACIONES  MODEL GRAFICO
        FECHA: date
      }

      // CONVERTIR DATOS A MODELO DE BASE DE DATOS
      this.modelGraphicsList(newPlan)

      this.$store.dispatch('incendio/addPlanOperaciones', newPlan) // ENVIAR AL STORE BD
      this.closeDialog()
    },

    editPlan () { // EDITAR UN PLAN EXISTENTE
      let date = this.$date.parseDate(this.fecha)
      let editPlan = {
        ID_PLAN_OPERACIONES: this.planSelected.ID_PLAN_OPERACIONES,
        ID_INCENDIO: this.idIncendio,
        NOMBRE: this.nombre,
        DESCRIPCION: this.descripcion,
        GRAFICOS: this.graphicList,
        FECHA: date
      }

      // CONVERTIR DATOS A MODELO DE BASE DE DATOS
      this.modelGraphicsList(editPlan)

      this.$store.dispatch('incendio/editPlanOperaciones', editPlan) // ENVIAR AL STORE BD
      this.closeDialog()
    },

    modelGraphicsList (plan) { // CONVERTIR GRÁFICOS A MODELO DE BASE DE DATOS
      for (let i = 0; i < plan.GRAFICOS.length; i++) {
        let g = plan.GRAFICOS[i]
        g.ID_GRAFICO = g.attributes.ID_GRAFICO // ASIGNAR ID DEL GRÁFICO
        g.ID_PLAN_OPERACIONES = plan.ID_PLAN_OPERACIONES

        // FIX PARSEO DE COLORES
        if (g.symbol.color && g.symbol.color.toRgba) { // CONVERTIR COLOR A RGBA
          g.symbol.color = g.symbol.color.toRgba()
        }
        if (g.symbol.outline && g.symbol.outline.color.toRgba) {
          g.symbol.outline.color = g.symbol.outline.color.toRgba()
        }
      }

      return plan
    },

    deletePlan () { // ELIMINAR UN PLAN
      let msg = 'Borrando plan: ' + this.planSelected.NOMBRE

      this.$root.$confirmDialog.open('¿Está seguro?', msg).then(result => {
        if (result) {
          this.$store.dispatch('incendio/deletePlanOperaciones', this.planSelected) // ELIMINAR DEL STORE
          this.cerrar()
        }
      })
    },

    closeDialog () {
      this.cerrar()

      // RESETEAR VALORES DEL FORMULARIO
      this.nombre = null
      this.descripcion = null
      this.fecha = null
      this.graphicList = []
    },

    cerrar () { // EMITE UN EVENTO PARA CERRAR EL DIÁLOGO
      this.$emit('close')
      ArcGIS.drawGraphicsPlanOperaciones([]) // DIBUJAR UNA LISTA VACÍA EN EL MAPA
    }
  },

  // MONTADO
  mounted () {
    this.$eventHub.$on('showAlineacionesCampbell', this.cerrar) // EVENTO PARA CERRAR
  }
}
</script>

<style scoped>
</style>
