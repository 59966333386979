<template>
  <v-card flat class="mt-4 layerItem">
    <v-card-title>
      <v-switch hide-details :label="item.title" v-model="visible" />
    </v-card-title>

    <v-card-text>
      <v-slider v-if="opacity > 0" v-model="opacity" label="Opacidad" min="10" max="100" step="10" hide-details />
      <v-checkbox v-if="item.legend" label="Mostrar leyenda" v-model="showLeyenda" />
      <v-chip-group v-if="item.title=='Pistas'" multiple active-class="primary--text" v-model="selectionValue">
        <v-chip v-for="id in selectOption" :key="id.nombre" :value="id.value" color="secondary"> {{ id.nombre }} </v-chip>
      </v-chip-group>
      <v-row v-show="showLeyenda" no-gutters>
        <v-col
          v-for="(legend, index) in item.legend"
          :key="index"
        >
          <v-list>
            <v-list-item class="pa-0 ma-0">
              <v-list-item-avatar class="pa-0 ma-0">
                <v-avatar size="25" class="color-square" :style="{ backgroundColor: legend.color }" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ legend.nombre }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

import * as ArcGIS from '@/helpers/ArcGIS.js'

export default {
  props: {
    item: {
      type: Object,
      default: () => { return {} }
    }
  },

  data: () => ({
    visible: false,
    opacity: null,
    showLeyenda: false,
    selectOption: [
      { nombre: 'Todoterreno', value: "NMI_TODOTE <> 'NO'" },
      { nombre: 'Autobomba', value: "NMI_AUTOBO <> 'NO'" },
      { nombre: 'Nodriza', value: "NMI_NODRIZ <> 'NO'" },
      { nombre: 'Góndola', value: "NMI_GONDOL <> 'NO'" }
    ],
    selectionValue: []

  }),
  computed: {
    filter () {
      return this.selectionValue.join(' AND ')
    }
  },

  watch: {
    visible () {
      if (this.visible) {
        this.$emit('setVisible')
      }

      this.setVisibilityLayer({
        id: this.item.id,
        visible: this.visible,
        item: this.item
      })
      ArcGIS.setVisibilityLayer(this.item.id, this.visible)
    },
    opacity () {
      this.setOpacityLayer({
        id: this.item.id,
        opacity: this.opacity / 100,
        item: this.item
      })
      ArcGIS.setOpacityLayer(this.item.id, this.opacity / 100)
    },
    filter () {
      ArcGIS.setDefinitionExpressionLayer('layer-trabajo-pistas', this.filter)
    }
  },

  methods: {
    ...mapActions('map', [
      'setVisibilityLayer',
      'setOpacityLayer'
    ])

  },

  created () {
    this.visible = this.item.visible

    if (this.item.opacity) {
      this.opacity = this.item.opacity * 100
    }
  }
}
</script>

<style scoped>
  .layerItem {
    background: #ebebeb;
    border-radius: 10px;
  }

  .color-square {
  border-radius: 0;
}
</style>
