import api from '@/api'

let Q = require('q')

const state = {
  bases: []
}

const getters = {
  bases: (state) => {
    return state.bases
  }
}

const mutations = {
  setBases (state, bases) {
    state.bases = bases
  }
}

const actions = {
  async fetchBases ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.base.getBases()
      commit('SET_BASES', response.data)

      deferred.resolve()
    } catch (err) {
      deferred.reject(err)
    }

    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
