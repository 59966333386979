<template>
  <v-card tile height="100%" id="ventana">
    <v-toolbar color="primaryLight" dark dense style="z-index: 2;">
      <v-toolbar-title>Planificación medios aéreos</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-container fluid class="pa-0">
        <!-- Barra superior -->
        <v-row align="center" no-gutters>
          <v-col>
            <v-select v-model="incendioSelected" :items="incendiosActivos" :item-text="item => item.NOMBRE ? item.NOMBRE : item.MUNICIPIO" label="Incendios" persistent-hint return-object />
          </v-col>

          <v-col>
            <v-text-field v-model="fechaIncendio" label="Fecha incendio" readonly />
          </v-col>

          <v-col>
            <!-- <vx-date-text-edit ref="horaSalidaBase" v-model="horaSalidaBase" label="Hora salida base" :max="horaSalidaMax" required/> :min="horaSalidaMin"  -->
            <VueCtkDateTimePicker
              v-model="horaSalidaBaseISO" no-value-to-custom-elem
              color="#00759e" button-now-translation="Ahora"
              format="YYYY-MM-DD HH:mm" :min-date="minDate" :max-date="maxDate"
            >
              <v-text-field v-model="horaSalidaBase" label="Hora salida base" readonly />
            </VueCtkDateTimePicker>
          </v-col>

          <v-col sm="1">
            <v-text-field v-model="orto" label="Orto" readonly />
          </v-col>

          <v-col sm="1">
            <v-text-field v-model="ocaso" label="Ocaso" readonly />
          </v-col>

          <v-col>
            <v-text-field v-model="fechaActual" label="Fecha/hora actual" readonly />
          </v-col>

          <v-col sm="3">
            <v-select v-model="filtrosSelected" :items="filtros" label="Filtros" multiple return-object>
              <template #selection="{ item, index }">
                <v-chip small v-if="index === 0">
                  <span>{{ item }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption">(+{{ filtrosSelected.length - 1 }} otros)</span>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <!-- Tabs -->
        <v-toolbar color="primaryLight" dense style="z-index: 2">
          <v-tabs v-model="tabSelected" color="white" background-color="primaryLight" dark slider-color="white">
            <v-tab v-for="t in tabs" :key="t" ripple @click="cambiarTab(t)">{{ t }}</v-tab>
          </v-tabs>
        </v-toolbar>

        <v-tabs-items v-model="tabSelected">
          <v-tab-item key="Gráfica">
            <v-container fluid class="pa-0">
              <v-row no-gutters>
                <v-col>
                  <v-card flat>
                    <v-card-title>
                      Real
                      <v-btn :disabled="!tiemposReal || tiemposReal.length === 0" class="ml-5" small fab icon @click="capturarGrafica()">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                      <v-btn :disabled="!tiemposReal || tiemposReal.length === 0" small fab icon @click="sendGraficoTelegram()">
                        <v-icon>mdi-share</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <div id="timelineReal" />
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col>
                  <v-card flat>
                    <v-card-title>Planificación</v-card-title>
                    <v-card-text>
                      <div id="timelinePlanificacion" />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <v-tab-item key="Tabla">
            <v-card flat>
              <v-card-text>
                <v-data-table
                  :headers="headers" :items="tiemposReal" :expanded="expandedRows" item-key="ID_MEDIO"
                  single-expand show-expand fixed-header height="calc(60vh - 20px)"
                  hide-default-footer disable-pagination
                >
                  <template #no-data>
                    <p>No hay medios asignados.</p>
                  </template>

                  <template #item="{ item, expand, isExpanded }">
                    <tr @click="expand(!isExpanded)">
                      <td>
                        <v-icon v-show="isExpanded">mdi-chevron-up</v-icon>
                        <v-icon v-show="!isExpanded">mdi-chevron-down</v-icon>
                      </td>
                      <td>{{ item.MEDIO }}</td>
                      <td class="text-xs">{{ item.TIPO }}</td>
                      <td class="text-xs">{{ hhmm(item.HORA_SALIDA_BASE) }}</td>
                      <td class="text-xs">{{ item.TIEMPO_MAX_VUELO }}h</td>
                      <td class="text-xs">{{ min(item.FERRY_INCENDIO) }}min</td>
                      <td class="text-xs">{{ horasmin(item.TIEMPO_ACTUACION_TOTAL) }}</td>
                    </tr>
                  </template>

                  <template #expanded-item="{ item }">
                    <tr>
                      <td :colspan="headers.length">
                        <div class="d-flex justify-center">
                          <v-simple-table class="mx-2" v-for="(p, i) in item.PERIODOS" :key="i" dense>
                            <template #default>
                              <thead>
                                <tr>
                                  <th colspan="2" class="text-center">Periodo {{ i + 1 }}</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr><td>Salida base: </td><td> {{ hhmm(p.horaSalidaBase) }}</td></tr>
                                <tr><td>Llegada incendio: </td><td> {{ hhmm(p.horaLlegadaInc) }}</td></tr>
                                <tr><td>Fin actuación: </td><td> {{ hhmm(p.finActuacionInc) }}</td></tr>
                                <tr><td>Llegada base: </td><td> {{ hhmm(p.horaLlegadaBase) }}</td></tr>
                                <tr v-if="p.horaFinDescanso"><td>Fin descanso: </td><td> {{ hhmm(p.horaFinDescanso) }}</td></tr>
                                <tr v-if="p.demora"><td>Demora: </td><td> {{ p.demora }}</td></tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </div>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-card-text>

    <vx-dialog-add-medio-planificacion
      :show="showDialog"
      :horaSalidaMin="horaSalidaMin"
      :horaSalidaMax="horaSalidaMax"
      :medioSelected="medioSelected"
      :idIncendio="incendioSelected ? incendioSelected.ID_INCENDIO : null"
      @cancelar="showDialog = false"
      @aceptar="addMedioIncendio"
    />

    <vx-popup-descanso :medioSelected="medioSelected" @showDescansoPopUp="showDescansoPopUp" @cancelar="showDescansoPopUp = false" @aceptar="changeDescansoPopUp" />
  </v-card>
</template>

<script>
import { getSunrise, getSunset } from 'sunrise-sunset-js'
import { Timeline, DataSet } from 'vis-timeline/standalone'

import api from '@/api'
import constants from '../../helpers/constants'
import * as PH from '../../helpers/PlanificacionHelper'
import * as htmlToImage from 'html-to-image'

import DialogAddMedioPlanificacionMMAA from './DialogAddMedioPlanificacionMMAA'
import PopUpDescanso from './PopUpDescanso'

import Q from 'q'

import { mapGetters } from 'vuex'

export default {
  components: {
    'vx-dialog-add-medio-planificacion': DialogAddMedioPlanificacionMMAA,
    'vx-popup-descanso': PopUpDescanso
  },

  data: () => ({
    oldIncendioSelected: null,
    oldIncendiosActivos: null,
    incendioSelected: null,
    horaSalidaBase: null,
    fechaActual: null,

    filtrosSelected: [],

    tabs: ['Gráfica', 'Tabla'],
    tabSelected: null,

    headers: [
      { text: '', value: 'data-table-expand' },
      { text: 'Medio', value: 'MEDIO', align: 'left', sortable: false },
      { text: 'Tipo', value: 'TIPO', align: 'left' },
      { text: 'Hora de salida', value: 'HORA_SALIDA_BASE', align: 'left' },
      { text: 'T. máx. vuelo', value: 'TIEMPO_MAX_VUELO', align: 'left' },
      { text: 'Ferry a inc.', value: 'FERRY_INCENDIO', align: 'left' },
      { text: 'T. actuación total', value: 'TIEMPO_ACTUACION_TOTAL', align: 'left' }
    ],
    expandedRows: [],

    medios: [],
    mediosReal: [],

    tiemposPlanificacion: [],
    tiemposReal: [],
    tiemposRealPasado: [],

    timelineReal: null,
    timelinePlanificacion: null,
    firstTimeFit: false,

    showDialog: false,
    medioSelected: null,

    showDescansoPopUp: false
  }),

  computed: {
    ...mapGetters('medio', [
      'tiposMedio'
    ]),

    filtros () {
      return this.tiposMedio.filter(element => element.ID_MEDIO_CATEGORIA === 'FC75C0E7-AF61-4B1D-AD6B-517113F7452E').map(element => element.TIPO)
    },

    incendiosActivos () {
      let incendios = JSON.parse(JSON.stringify(this.$store.getters['incendio/incendios']))
      incendios.sort((a, b) => {
        let aEstado = a.VALUE_ESTADO
        let bEstado = b.VALUE_ESTADO
        let aFecha = a.FECHA_CREACION
        let bFecha = b.FECHA_CREACION

        if (aEstado === bEstado) {
          return (aFecha > bFecha) ? -1 : (aFecha < bFecha) ? 1 : 0
        } else {
          return (aEstado < bEstado) ? -1 : 1
        }
      })
      return incendios
      // console.log('INCENDIOS')
      // return this.$store.getters['incendio/incendios']
    },

    fechaIncendio () {
      let fecha = null
      if (this.incendioSelected && this.incendioSelected.FECHA_CREACION) {
        fecha = this.$date.formatDate(this.incendioSelected.FECHA_CREACION, 'DD/MM/YYYY HH:mm')
      }

      return fecha
    },

    orto () {
      let orto = null
      let horaSalidaBase = this.$date.parseDate(this.horaSalidaBase)
      if (this.incendioSelected && this.incendioSelected.LATITUD && horaSalidaBase.isValid()) {
        let sunrise = getSunrise(this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD, horaSalidaBase.toDate())
        orto = this.$date.formatDate(sunrise.toISOString(), 'HH:mm')
      }

      return orto
    },
    ocaso () {
      let ocaso = null
      let horaSalidaBase = this.$date.parseDate(this.horaSalidaBase)
      if (this.incendioSelected && this.incendioSelected.LATITUD && horaSalidaBase.isValid()) {
        let sunset = getSunset(this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD, horaSalidaBase.toDate())
        ocaso = this.$date.formatDate(sunset.toISOString(), 'HH:mm')
      }

      return ocaso
    },

    horaSalidaMin () {
      let fecha = null
      if (this.incendioSelected && this.incendioSelected.LATITUD) {
        let now = this.$date.now()
        let orto = this.$date.parseDate(getSunrise(this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD, new Date()))
        fecha = orto < now ? now.format('DD/MM/YYYY HH:mm') : orto.format('DD/MM/YYYY HH:mm')
      }

      return fecha
    },
    horaSalidaMax () {
      let fecha = null
      if (this.incendioSelected && this.incendioSelected.LATITUD) {
        fecha = this.$date.parseDate(getSunset(this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD, new Date())).add(1, 'days').format('DD/MM/YYYY HH:mm')
      }

      return fecha
    },

    /* Fechas en formato ISO para el DateTimePicker */
    horaSalidaBaseISO: {
      get: function () {
        let date = this.horaSalidaBase ? this.$date.parseDate(this.horaSalidaBase, 'DD/MM/YYYY HH:mm') : this.$date.now()
        return date.format('YYYY-MM-DD HH:mm')
      },
      set: function (newValue) {
        let date = this.$date.parseDate(newValue, 'YYYY-MM-DD HH:mm')
        this.horaSalidaBase = date.format('DD/MM/YYYY HH:mm')
      }
    },
    minDate () {
      return this.horaSalidaMin ? this.$date.formatDate(this.horaSalidaMin, 'YYYY-MM-DD HH:mm') : null
    },
    maxDate () {
      return this.horaSalidaMax ? this.$date.formatDate(this.horaSalidaMax, 'YYYY-MM-DD HH:mm') : null
    }
  },

  watch: {
    incendiosActivos (oldIn) { // Seleccionar primer incendio de la lista
      let is = this.incendioSelected ? this.incendiosActivos.find(x => x.ID_INCENDIO === this.incendioSelected.ID_INCENDIO) : null
      // console.log('Han cambiado los incendios', this.incendioSelected, is, this.incendioSelected === is)
      if (this.incendiosActivos.length > 0 && !this.incendioSelected) {
        this.incendioSelected = this.incendiosActivos[0]
        this.oldIncendiosActivos = this.incendiosActivos
      }
      if (JSON.stringify(this.oldIncendiosActivos) === JSON.stringify(oldIn)) return

      if (this.incendioSelected) { // HACK: el refresher modifica los incendios y luego el watch de 'incendioSelected.SECTORES' no funciona
        is = this.incendiosActivos.find(x => x.ID_INCENDIO === this.incendioSelected.ID_INCENDIO)
        this.incendioSelected = is
        this.oldIncendiosActivos = this.incendiosActivos
      }
    },

    horaSalidaBase (newHora) { // Si cambias de hora, actualizar planificadas
      if (JSON.stringify(this.oldHoraSalidaBase) === JSON.stringify(newHora)) return

      let horaSalidaBase = this.$date.parseDate(this.horaSalidaBase, 'DD/MM/YYYY HH:mm')

      if (this.timelinePlanificacion) {
        this.tiemposPlanificacion.forEach((t) => {
          t.HORA_SALIDA_BASE = horaSalidaBase // Asignar hora de salida de base (la del selector de arriba)
        })

        this.actualizarDatos('planificacion')
      }
    },

    incendioSelected (newIn) { // Si cambias de incendio, recalcular
      // console.log('El incendio seleccionado ha cambiado', this.incendioSelected.ID_INCENDIO)
      if (!this.timelinePlanificacion || !this.timelineReal) {
        this.initTimelines()
      }

      if (JSON.stringify(newIn) !== JSON.stringify(this.oldIncendioSelected) || !this.oldIncendioSelected) {
        this.oldIncendioSelected = this.incendioSelected
        this.mediosAsignadosIncendio()

        this.calcularDistanciasIncendio('real')
        this.calcularDistanciasIncendio('planificacion')

        this.actualizarDatos('real')
        this.actualizarDatos('planificacion')
      }

      /* this.mediosAsignadosIncendio()

      this.calcularDistanciasIncendio('real')
      this.calcularDistanciasIncendio('planificacion')

      this.actualizarDatos('real')
      this.actualizarDatos('planificacion') */
    },

    'incendioSelected.SECTORES': { // Este watch controla cuando se añaden y/o borran medios
      handler (val) {
        if (JSON.stringify(this.incendioSelected.SECTORES) === JSON.stringify(val)) return
        // if (oldVal) {
        /* this.mediosAsignadosIncendio()
          this.calcularDistanciasIncendio('real')
          this.actualizarDatos('real') */

        this.mediosAsignadosIncendio()

        this.calcularDistanciasIncendio('real')
        this.calcularDistanciasIncendio('planificacion')

        this.actualizarDatos('real')
        this.actualizarDatos('planificacion')
        // }
      },
      deep: true
    },

    filtrosSelected () {
      this.filtrarDatos()
    }
  },

  methods: {
    setFiltros () {
      this.filtrosSelected = this.filtros
    },

    async init () {
      this.horaSalidaBase = this.$date.currentDate()
      this.fechaActual = this.$date.currentDate()

      await this.loadMediosAereos()

      // Seleccionar primer incendio
      if (this.incendiosActivos.length > 0 && !this.incendioSelected) {
        this.incendioSelected = this.incendiosActivos[0]
      }

      // this.incendioSelected = this.incendiosActivos[0] // Al poner el primer incendio, se llama al watch que ya actualiza los datos
      // this.initTimelines()

      //
      /* this.timer = setInterval(() => {
        if (this.timelineReal && this.timelinePlanificacion) {
          this.actualizarDatos('real')
          this.actualizarDatos('planificacion')
        }
      }, 5 * 60000) */

      // El timeout hace que salte exactamente en el segundo 00
      let secondsLeft = 60 - new Date().getSeconds()
      this.timeout = setTimeout(() => {
        this.fechaActual = this.$date.currentDate()
        // this.actualizarDatos('real')
        // this.actualizarDatos('planificacion')

        // this.timer = setInterval(() => {
        //   this.fechaActual = this.$date.currentDate()
        //   if (this.timelineReal && this.timelinePlanificacion) {
        //     this.actualizarDatos('real')
        //     this.actualizarDatos('planificacion')
        //   }
        // }, 1 * 60000)
      }, secondsLeft * 1000)
    },

    loadMediosAereos () { // Carga la lista de medios aereos
      let deferred = Q.defer()

      api.planificacion.getMediosAereos().then(response => {
        if (response.status === 200) {
          this.medios = response.data
          deferred.resolve()
        } else {
          deferred.reject()
        }
      }).catch(err => {
        deferred.reject(err)
      })

      return deferred.promise
    },

    initTimelines () { // Inicializa las timelines
      if (!this.timelineReal) {
        let divReal = document.getElementById('timelineReal')
        if (divReal) {
          this.timelineReal = new Timeline(divReal)
          this.timelineReal.setOptions(this.optionsTimeLine('real'))
          this.timelineReal.on('click', (properties) => {
            if (properties.item) {
              let item = this.timelineReal.itemsData.get(properties.item)
              if (item.content === 'Descanso') {
                let medio = this.tiemposReal.find((a) => a.ID_MEDIO === properties.group)
                this.showDescansoPopUp = true

                let position = {
                  x: properties.pageX - 100,
                  y: properties.pageY - 50
                }
                this.$eventHub.$emit('showPopUpDescanso', {
                  medio: medio,
                  position: position,
                  item: item
                })
              }
            }
          })
        }
      }

      if (!this.timelinePlanificacion) {
        let divPlanificacion = document.getElementById('timelinePlanificacion')
        if (divPlanificacion) {
          this.timelinePlanificacion = new Timeline(divPlanificacion)
          this.timelinePlanificacion.setOptions(this.optionsTimeLine('planificacion'))
        }
      }
    },

    optionsTimeLine (idTimeline) {
      let orto = getSunrise(this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD, new Date())
      let ocaso = getSunset(this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD, new Date())

      let minTimeLine = this.$date.parseDate(orto).subtract(2, 'hours')
      if (idTimeline === 'real') {
        minTimeLine = this.$date.parseDate(this.incendioSelected.FECHA_CREACION)
      }

      let maxTimeLine = this.$date.parseDate(ocaso).add(2, 'days')

      let initTimeLine = minTimeLine
      let endTimeLine = this.$date.now().add(12, 'hours')

      let options = {
        groupOrder: (a, b) => {
          if (idTimeline === 'real') { // TODO:
            return a.initTime < b.initTime ? -1 : 1
          } else return b.value - a.value
        },

        groupTemplate: (group) => {
          return this.getGroupTemplate(group, idTimeline)
        },

        loadingScreenTemplate: function () {
          return '<h2>Cargando...</h2>'
        },

        // margin: 20,
        align: 'bottom',
        stack: false,
        showTooltips: true,
        tooltip: {
          followMouse: true,
          overflowMethod: 'cap'
        },
        orientation: 'top',
        zoomMin: 100000,
        // groupHeightMode: 'fixed',
        maxHeight: 'calc(80vh - 200px)',
        verticalScroll: true,
        zoomKey: 'ctrlKey',
        min: minTimeLine.valueOf(), // TODO: Problema de DayJS y VIS (leer en Actuaciones.vue)
        max: maxTimeLine.valueOf(),
        start: initTimeLine.valueOf(),
        end: endTimeLine.valueOf()
      }

      return options
    },

    getGroupTemplate (group, idTimeline) { // HTML que se renderiza dentro de la casilla de grupo
      let m = this.getMedioById(group.id)

      // Contenedor
      let container = document.createElement('div')

      // Contenido
      let label = document.createElement('div')
      label.className = 'group'
      label.innerHTML = '(' + m.BASE.split(' ')[0] + ') ' + group.content + '     '
      container.insertAdjacentElement('afterBegin', label)

      // Tooltip
      let tooltip = document.createElement('span')
      tooltip.className = 'tooltiptext'
      tooltip.innerHTML = 'Base: ' + m.BASE + '<br>Tipo: ' + m.TIPO + (m.MODELO ? ' |\n Modelo: ' + m.MODELO : '')
      label.insertAdjacentElement('afterBegin', tooltip)

      // Boton añadir
      let button = document.createElement('button')
      button.className = 'fabBtn' // 'buttonAdd'
      // button.innerHTML = '+'
      let icon = document.createElement('i')
      icon.className = 'mdi mdi-plus'
      button.insertAdjacentElement('afterBegin', icon)
      button.title = 'Añadir'
      // button.style.float = 'right'
      container.insertAdjacentElement('afterBegin', button)

      if (idTimeline === 'real' && !group.pasado) { // No muestra boton ni checkbox si es un grupo con solo actuaciones pasadas
        // button.innerHTML = '-'
        icon.className = 'mdi mdi-minus'
        button.title = 'Borrar'
        button.addEventListener('click', () => {
          this.borrarMedioIncendio(group.id, group.idMedioSector)
        })
        // container.insertAdjacentElement('beforeEnd', button)

        // Check Agoncillo
        let cb = document.createElement('input')
        cb.checked = m.AGONCILLO
        cb.type = 'checkbox'
        cb.className = 'cbAgoncillo'
        cb.addEventListener('click', () => {
          let medioModificado = this.mediosReal.find(x => x.ID_MEDIO_SECTOR === group.idMedioSector)
          medioModificado.AGONCILLO = cb.checked

          this.incendioSelected.SECTORES.forEach((s) => {
            let medioSector = s.MEDIOS.find(x => x.ID_MEDIO_SECTOR === group.idMedioSector)

            if (medioSector) {
              let data = {
                ID_MEDIO_SECTOR: medioSector.ID_MEDIO_SECTOR,
                AGONCILLO: cb.checked
              }
              api.planificacion.setVueltaAgoncillo(data).then(response => {
                if (response.status === 200) {
                  this.$notify({
                    title: `Medio ${m.MEDIO}`,
                    text: 'Agoncillo modificado'
                  })
                }
              }).catch(err => {
                this.$log.error('Error setVueltaAgoncillo', err)
                this.$notify({
                  title: `Medio ${m.MEDIO}`,
                  text: 'Error al modificar Agoncillo',
                  type: 'error'
                })
              })
            }
          })
          this.calcularDistanciasIncendio('real')
        })

        let l = document.createElement('label')
        l.innerHTML = 'RJL'
        l.style.float = 'right'
        l.style.marginLeft = '5px'
        // l.style.width = '35%'
        l.insertAdjacentElement('beforeEnd', cb)
        label.insertAdjacentElement('beforeEnd', l)
      } else {
        button.addEventListener('click', () => {
          this.showDialogAddMedio(group.id)
        })

        // Bloqueo el botón si no tieneinitTime
        if (!group.initTime) {
          button.disabled = true
        }

        if (group.yaAsignado) { // Modifica el boton para aquellos ya asignados a otro incendio
          let incendioAsignado = this.$store.getters['incendio/getIncendioByID'](m.ID_INCENDIO) // TODO: coger de la lista de todos los incendios
          if (incendioAsignado) {
          // button.innerHTML = 'En ' + incendioAsignado.MUNICIPIO
          // button.innerHTML = 'x'
            icon.className = 'mdi mdi-close'
            button.title = 'En ' + incendioAsignado.MUNICIPIO
            button.disabled = true
          }
        }
        // container.insertAdjacentElement('beforeEnd', button)
      }

      return container
    },

    calcularDistanciasIncendio (idTimeline) { // Calcula el tiempo de ferry a incendio de cada medio y guarda la hora de salida
      let listaMedios = idTimeline === 'real' ? this.mediosReal : this.medios
      let items = PH.calculateDistanceTimeToIncendio(listaMedios, this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD)

      if (idTimeline === 'planificacion') {
        items = items.filter(x => !x.HORA_FIN_DESCANSO || this.$date.parseDate(x.HORA_FIN_DESCANSO) <= this.$date.now()) // Eliminar los que esten descansando

        this.tiemposPlanificacion = items

        // TODO: revisar si es necesario esto
        /* this.tiemposPlanificacion.forEach((t) => { // Asignar hora de salida de base (la del selector de arriba)
          t.HORA_SALIDA_BASE = this.$date.parseDate(this.horaSalidaBase)
        }) */
      } else {
        // Ya tienen hora de salida y hora de llegada asignada
        this.tiemposReal = items
      }
      this.actualizarDatos(idTimeline)
    },

    mediosAsignadosIncendio () { // Separa en dos listas los medios asignados actualmente y los medios con actuaciones pasadas
      this.mediosReal = []
      this.tiemposRealPasado = []

      if (this.incendioSelected) {
        for (let i = 0; i < this.incendioSelected.SECTORES.length; i++) {
          let s = this.incendioSelected.SECTORES[i]

          for (let j = 0; j < s.MEDIOS.length; j++) {
            let m = s.MEDIOS[j]

            let medio = this.medios.find(x => x.ID_MEDIO === m.ID_MEDIO)

            if (medio) {
            // medio.vueltaAgoncillo = false
              if (!m.FECHA_LLEGADA_BASE) {
                medio.ID_MEDIO_SECTOR = m.ID_MEDIO_SECTOR
                medio.HORA_SALIDA_BASE = m.FECHA_AVISO ? m.FECHA_AVISO : null
                /* medio.horaLlegadaInc = m.FECHA_LLEGADA === undefined ? null : m.FECHA_LLEGADA
              medio.finActuacionInc = m.FECHA_SALIDA === undefined ? null : m.FECHA_SALIDA
              medio.horaLlegadaBase = m.FECHA_LLEGADA_BASE === undefined ? null : m.FECHA_LLEGADA_BASE */
                this.mediosReal.push(medio)
              } else {
                this.tiemposRealPasado.push({
                  ID_MEDIO: medio.ID_MEDIO,
                  ALIAS: medio.ALIAS,
                  HORA_SALIDA_BASE: m.FECHA_AVISO ? m.FECHA_AVISO : null,
                  MEDIO: medio.MEDIO,
                  TIEMPO_ACTUACION_TOTAL: this.$date.parseDate(m.FECHA_SALIDA).diff(this.$date.parseDate(m.FECHA_LLEGADA), 'minutes'),
                  TIEMPO_MAX_VUELO: medio.TIEMPO_MAX_VUELO,
                  TIPO: medio.TIPO,
                  PERIODOS: [{ // TODO: RENOMBRAR A FECHA_AVISO, ETC?
                    horaSalidaBase: this.$date.parseDate(m.FECHA_AVISO),
                    horaLlegadaInc: this.$date.parseDate(m.FECHA_LLEGADA),
                    finActuacionInc: this.$date.parseDate(m.FECHA_SALIDA),
                    horaLlegadaBase: this.$date.parseDate(m.FECHA_LLEGADA_BASE),
                    horaFinDescanso: m.FECHA_FIN_DESCANSO ? this.$date.parseDate(m.FECHA_FIN_DESCANSO) : null,
                    demora: null
                  }]
                })
              }
            }
          }
        }
      }
    },

    actualizarDatos (idTimeline) { // Actualiza los datos que se muestran en la timeline objetivo
      // let horaSalidaBase = this.$date.parseDate(this.horaSalidaBase)
      /* let orto = this.$date.parseDate(getSunrise(this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD, horaSalidaBase.toDate()))
      let ocaso = this.$date.parseDate(getSunset(this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD, horaSalidaBase.toDate()))

      if (horaSalidaBase < orto || horaSalidaBase > ocaso) {
        this.errorDate = true
      } else this.errorDate = false */

      // console.log('--------> ACTUALIZAR_DATOS', idTimeline)

      this.calcularPeriodos(idTimeline)
      if (idTimeline === 'planificacion') {
        this.updateTimeLinePlanificacion()
      } else {
        this.updateTimeLineReal()
      }
      this.setFiltros()
      this.filtrarDatos()
    },

    calcularPeriodos (idTimeline) { // Calcula los diferentes periodos de cada medio de la timeline objetivo
      let horaSalidaBase = this.$date.parseDate(this.horaSalidaBase, 'DD/MM/YYYY HH:mm')
      let orto = this.$date.parseDate(getSunrise(this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD, horaSalidaBase.toDate()))
      let ocaso = this.$date.parseDate(getSunset(this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD, horaSalidaBase.toDate()))

      let tiempos = idTimeline === 'real' ? this.tiemposReal : this.tiemposPlanificacion

      for (let i = 0; i < tiempos.length; i++) {
        let t = tiempos[i]

        t.PERIODOS = []

        let controlOcaso = this.$date.parseDate(t.HORA_SALIDA_BASE) // Empieza con la hora de salida de base inicial

        let tiempoVueloAgoncillo // Tiempo de vuelo a Agoncillo
        if (t.FERRY_AGONCILLO) {
          tiempoVueloAgoncillo = t.FERRY_AGONCILLO.split(':')[0] * 60 + parseInt(t.FERRY_AGONCILLO.split(':')[1])
        }

        let tiempoFerryMinutos = t.FERRY_INCENDIO.split(':')[0] * 60 + parseInt(t.FERRY_INCENDIO.split(':')[1])
        let tiempoVueltaBase = tiempoFerryMinutos
        if (tiempoVueloAgoncillo) { // Si tiene valor, lo usamos como tiempo de vuelta a base
          tiempoVueltaBase = tiempoVueloAgoncillo
        }

        while (controlOcaso && controlOcaso < ocaso) {
          let periodo = {}

          // Si es el primer periodo y la timeline real, añadir los tiempos ya existentes
          if (t.PERIODOS.length === 0 && idTimeline === 'real') {
            for (let j = 0; j < this.incendioSelected.SECTORES.length; j++) {
              let s = this.incendioSelected.SECTORES[j]

              for (let k = 0; k < s.MEDIOS.length; k++) {
                let m = s.MEDIOS[k]

                if (m.ID_MEDIO === t.ID_MEDIO && !m.FECHA_LLEGADA_BASE) { // Solo los activos en el momento
                  periodo.horaSalidaBase = m.FECHA_AVISO ? this.$date.parseDate(m.FECHA_AVISO) : null
                  periodo.horaLlegadaInc = m.FECHA_LLEGADA ? this.$date.parseDate(m.FECHA_LLEGADA) : null
                  periodo.finActuacionInc = m.FECHA_SALIDA ? this.$date.parseDate(m.FECHA_SALIDA) : null
                  periodo.horaLlegadaBase = m.FECHA_LLEGADA_BASE ? this.$date.parseDate(m.FECHA_LLEGADA_BASE) : null
                  periodo.horaFinDescanso = m.FECHA_FIN_DESCANSO ? this.$date.parseDate(m.FECHA_FIN_DESCANSO) : null
                }
              }
            }
          }

          // Si no es el primer periodo y vuelve de Agoncillo, usar ese tiempo
          if (t.PERIODOS.length > 0 && tiempoVueloAgoncillo) { // Si tiene valor, lo usamos como tiempo de ida a incendio
            tiempoFerryMinutos = tiempoVueloAgoncillo
          }

          // Demora
          if (this.$date.parseDate(controlOcaso) < ocaso) {
            periodo.demora = t.TIPO === 'AA' ? 30 : 10

            controlOcaso = this.$date.parseDate(controlOcaso).add(periodo.demora, 'minutes')
          } else controlOcaso = null

          // Hora salida base
          periodo.horaSalidaBase = this.$date.parseDate(controlOcaso)
          if (!periodo.horaSalidaBase.isValid() /* .format('HH:mm') === 'Invalid date' */) {
            periodo.horaSalidaBase = null
          }

          // Hora llegada incendio
          if (!periodo.horaSalidaBase || periodo.horaSalidaBase < orto) {
            periodo.horaLlegadaInc = null
          } else {
            if (periodo.horaSalidaBase >= ocaso) {
              periodo.horaLlegadaInc = 'Ocaso'
            } else {
              if (!periodo.horaLlegadaInc) { // Si ya tiene hora de llegada a incendio, la uso
                periodo.horaLlegadaInc = this.$date.parseDate(periodo.horaSalidaBase).add(tiempoFerryMinutos, 'minutes')
              }
            }
          }

          // Hora fin actuacion incendio
          if (!periodo.horaLlegadaInc) {
            periodo.finActuacionInc = null
          } else {
            let tiempoIdaIncendio = this.$date.parseDate(periodo.horaLlegadaInc).diff(this.$date.parseDate(periodo.horaSalidaBase), 'minutes')
            let tiempoVueloRestante = t.TIEMPO_MAX_VUELO * 60 - tiempoIdaIncendio - tiempoVueltaBase
            if (periodo.horaLlegadaInc >= ocaso || periodo.horaLlegadaInc === 'Ocaso') {
              periodo.finActuacionInc = 'Ocaso'
            } else {
              if (!periodo.finActuacionInc) { // Si ya tiene hora de salida de incendio, la uso
                periodo.finActuacionInc = this.$date.parseDate(periodo.horaLlegadaInc).add(tiempoVueloRestante, 'minutes')
              }
            }
          }

          // Hora llegada base
          if (!periodo.finActuacionInc) {
            periodo.horaLlegadaBase = null
          } else {
            if (periodo.finActuacionInc >= ocaso || periodo.finActuacionInc === 'Ocaso') {
              periodo.horaLlegadaBase = 'Ocaso'
            } else {
              if (!periodo.horaLlegadaBase) { // Si ya tiene hora de llegada a base, la uso
                periodo.horaLlegadaBase = this.$date.parseDate(periodo.finActuacionInc).add(tiempoVueltaBase, 'minutes')
              }
            }
          }

          // Hora fin descanso
          if (!periodo.horaLlegadaBase) {
            periodo.horaFinDescanso = null
          } else {
            try {
              if (periodo.horaLlegadaBase >= ocaso || periodo.horaLlegadaBase === 'Ocaso') {
                periodo.horaFinDescanso = 'Ocaso'
              } else {
                if (!periodo.horaFinDescanso) {
                  let tiempoVuelo = periodo.horaLlegadaBase.diff(periodo.horaSalidaBase, 'minutes')
                  let descanso = Math.ceil(tiempoVuelo / 60) * 20 // 20 min x hora de vuelo

                  if (t.TIPO === 'ACO-Avión') {
                    if (tiempoVuelo > constants.tiempoMaxVuelo.aco_avion * 60) { // Si ACO ha volado + de su maximo, solo descansa 1 hora
                      descanso = 60
                    } else if (descanso < 10) { // Si a ACO le corresponden - de 10 min de descanso, descansa 10 min
                      descanso = 10
                    }
                  } else if (t.TIPO === 'ACT') {
                    if (tiempoVuelo > constants.tiempoMaxVuelo.act * 60) { // Si ACT ha volado + de su maximo, solo descansa 1 hora
                      descanso = 60
                    } else if (descanso < 10) { // Si a ACT le corresponden - de 10 min de descanso, descansa 10 min
                      descanso = 10
                    }
                  } else if (t.TIPO === 'AA') {
                    if (tiempoVuelo > constants.tiempoMaxVuelo.hidroavion * 60) { // Si Hidroavion ha volado + de su maximo, solo descansa 1 hora 20 min
                      descanso = 80
                    } else if (descanso < 30) { // Si a Hidroavion le corresponden - de 30 min de descanso, descansa 30 min
                      descanso = 30
                    }
                  } else {
                    if (tiempoVuelo > 2 * 60) { // Si ha volado + de su maximo (2h), solo descansa 40 min
                      descanso = 40
                    } else if (descanso < 10) { // Si le corresponden - de 10 min de descanso, descansa 10 min
                      descanso = 10
                    }
                  }

                  let periodPlan = t.PERIODOS_PLANIFICACION.find((a) => a.INICIO_PERIODO === periodo.horaLlegadaBase.toDate().toISOString())

                  if (periodPlan) {
                    descanso = periodPlan.DURACION_PERIODO
                  }

                  periodo.horaFinDescanso = this.$date.parseDate(periodo.horaLlegadaBase).add(descanso, 'minutes')

                  t.PERIODOS.push(periodo)
                  controlOcaso = this.$date.parseDate(periodo.horaFinDescanso)
                }
              }
            } catch (exception) { }
          }
        }

        // Demora
        /* if (periodo.horaFinDescanso === undefined) {
            periodo.demora = undefined
            controlOcaso = undefined
          } else {
            if (periodo.horaFinDescanso < ocaso || periodo.horaFinDescanso !== 'Ocaso') {
              periodo.demora = 10
              if (t.TIPO === 'AA') {
                periodo.demora = 30
              }

              t.PERIODOS.push(periodo)
              controlOcaso = moment(periodo.horaFinDescanso).add(periodo.demora, 'minutes')
            } else controlOcaso = undefined
          } */

        // Ajustar ultimo periodo
        let ultimoPeriodo = t.PERIODOS[t.PERIODOS.length - 1]
        if (ultimoPeriodo) {
          // let salidaBase = ultimoPeriodo !== undefined ? moment(ultimoPeriodo.horaFinDescanso).add(ultimoPeriodo.demora, 'minutes') : moment(this.horaSalidaBase)
          let salidaBase = this.$date.parseDate(ultimoPeriodo.horaFinDescanso).add(ultimoPeriodo.demora, 'minutes')

          // Añadir un periodo extra, ajustado al tiempo restante
          if (salidaBase < ocaso) {
            let demora = t.TIPO === 'AA' ? 30 : 10
            let tiempoRestante = ocaso.diff(salidaBase, 'minutes') // Tiempo restante desde salida al ocaso
            let llegadaInc = this.$date.parseDate(salidaBase).add(tiempoFerryMinutos, 'minutes')
            let finActuacion = this.$date.parseDate(llegadaInc).add(tiempoRestante - (2 * tiempoFerryMinutos), 'minutes')
            let llegadaBase = this.$date.parseDate(finActuacion).add(tiempoFerryMinutos, 'minutes')

            if (this.$date.parseDate(finActuacion).diff(llegadaInc, 'minutes') >= 1.5 * tiempoFerryMinutos) { // Solo se añade un nuevo periodo si el tiempo de actuacion es mayor o igual al transito
              t.PERIODOS.push({
                demora: demora,
                horaSalidaBase: salidaBase,
                horaLlegadaInc: llegadaInc,
                finActuacionInc: finActuacion,
                horaLlegadaBase: llegadaBase
              })
            }
          }

          // Quitar descanso y demora ultimo periodo
          ultimoPeriodo = t.PERIODOS[t.PERIODOS.length - 1]
          if (ultimoPeriodo) {
            ultimoPeriodo.horaFinDescanso = null
            // ultimoPeriodo.demora = undefined
          }

          // Si la ida y vuelta se solapan, eliminar
          for (let x = 0; x < t.PERIODOS.length; x++) {
            let p = t.PERIODOS[x]
            if (p.horaLlegadaInc >= p.finActuacionInc) {
              t.PERIODOS.splice(x, 1)
              x--
            }
          }
        }
      }
    },

    filtrarDatos () { // Filtra los grupos de planificacion
      let ids = this.medios.filter(element => this.filtrosSelected.includes(element.TIPO)).map(t => t.ID_MEDIO)

      // Ocultar los grupos que no se deben ver: vacios, que ya estan en planif real o que no cumplen el filtro
      if (this.timelinePlanificacion && this.timelinePlanificacion.groupsData) {
        this.timelinePlanificacion.groupsData._data.forEach((group) => { // TODO:
          if (ids.includes(group.id) && this.mediosReal.find(t => t.ID_MEDIO === group.id) === undefined /* && !group.yaAsignado */) {
            this.timelinePlanificacion.groupsData._data.update({ id: group.id, visible: true })
          } else this.timelinePlanificacion.groupsData._data.update({ id: group.id, visible: false })
        })
      }
    },

    updateTimeLinePlanificacion () { // Actualiza la timeline de planificacion
      if (this.timelinePlanificacion) {
        this.setGroups(this.timelinePlanificacion, this.tiemposPlanificacion, 'planificacion')
        this.setItems(this.timelinePlanificacion, this.tiemposPlanificacion, 'planificacion')
      }
    },
    updateTimeLineReal () { // Actualiza la timeline real
      if (this.timelineReal) {
        this.setGroups(this.timelineReal, this.tiemposReal, 'real')
        this.setItems(this.timelineReal, this.tiemposReal, 'real')
      }
    },

    setGroups (timeline, tiempos, idTimeline) { // Crear los grupos de la timeline y los asigna
      let groups = []

      for (let i = 0; i < tiempos.length; i++) {
        let t = tiempos[i]

        // Tiempo de actuacion total, usado para ordenar
        t.TIEMPO_ACTUACION_TOTAL = 0

        // Si es la real, añadir el tiempoActuacion de las partes pasadas de los medios que esten actuando ahora
        /* if (idTimeline === 'real') {
          let tp = this.tiemposRealPasado.find(x => x.id === t.ID_MEDIO)

          if (tp) {
            t.tiempoActuacionTotal += tp.tiempoActuacionTotal
          }
        } */

        // Acumular tiempo actuacion
        t.PERIODOS.forEach((p) => {
          t.TIEMPO_ACTUACION_TOTAL += p.finActuacionInc.diff(p.horaLlegadaInc, 'minutes')
        })

        // Controla si tarda demasiado en viajar
        let vacio = false
        /* let tiempoFerryMinutos = t.ferryInc.split(':')[0] * 60 + parseInt(t.ferryInc.split(':')[1])
        if (tiempoFerryMinutos * 2 > t.tiempoMaxVuelo * 60) {
          vacio = true
        } */

        // Crear cada grupo (medio)
        if (!vacio) {
          let initTime = null // Tiempo inicial, para ordenar
          if (t.PERIODOS && t.PERIODOS[0]) {
            initTime = t.PERIODOS[0].horaSalidaBase
          }

          // Controla si ya esta asignado a otro incendio
          let yaAsignado = false
          let medio = this.getMedioById(t.ID_MEDIO)
          if (medio && medio.ID_INCENDIO && medio.ID_INCENDIO !== this.incendioSelected.ID_INCENDIO) {
            yaAsignado = true
          }

          groups.push({
            id: t.ID_MEDIO,
            idMedioSector: t.ID_MEDIO_SECTOR,
            content: '<span align="left">' + t.MEDIO.replace(/ *\([^)]*\) */g, ' ').trim() + '</span>',
            initTime: initTime,
            yaAsignado: yaAsignado,
            pasado: false,
            // title: 'Hola marco', // Tooltip default
            value: t.TIEMPO_ACTUACION_TOTAL,
            className: 'medio'
          })
        }
      }

      if (idTimeline === 'real') { // Si es la real, añadir los grupos de los medios pasados que ya no estan actuando
        for (let i = 0; i < this.tiemposRealPasado.length; i++) {
          let t = this.tiemposRealPasado[i]

          if (!groups.find(x => x.id === t.ID_MEDIO)) {
            groups.push({
              id: t.ID_MEDIO,
              idMedioSector: t.ID_MEDIO_SECTOR,
              content: '<span align="left">' + t.MEDIO + '</span>',
              initTime: t.HORA_SALIDA_BASE,
              yaAsignado: false,
              pasado: true,
              value: t.TIEMPO_ACTUACION_TOTAL,
              className: 'medio'
            })
          }
        }
      }
      let dataSetGroups = new DataSet(groups)
      timeline.setGroups(dataSetGroups)
    },
    setItems (timeline, tiempos, idTimeline) { // Crea los items de fondo (dia-noche) y los añade a la timeline junto con los items de los medios
      // Get lista de items
      let items = this.getItems(tiempos, idTimeline)

      let horaSalidaBase = this.$date.parseDate(this.horaSalidaBase)
      let sunrise = this.$date.parseDate(getSunrise(this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD, horaSalidaBase.toDate()))
      let sunset = this.$date.parseDate(getSunset(this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD, horaSalidaBase.toDate()))

      // Item background dia
      let item = {
        id: 'dia',
        content: 'Día',
        start: sunrise,
        end: sunset,
        type: 'background',
        className: 'background-dia'
      }
      items.push(item)

      // Item background noche (antes)
      item = {
        id: 'noche1',
        content: 'Noche',
        start: sunset.subtract(1, 'days'),
        end: sunrise,
        type: 'background',
        className: 'background-noche'
      }
      items.push(item)

      // Item background noche (despues)
      item = {
        id: 'noche2',
        content: 'Noche',
        start: sunset,
        end: sunrise.add(1, 'days'),
        type: 'background',
        className: 'background-noche'
      }
      items.push(item)

      items = PH.convertDatesToUNIX(items)

      let dataSetItems = new DataSet(items)
      timeline.setItems(dataSetItems)

      // Fit timeline
      if (!this.firstTimeFit) { // Solo lo hace la primera vez
        if (idTimeline === 'real') {
          setTimeout(() => { // Sin el timeout no hace caso...
            this.firstTimeFit = true
            this.timelineReal.setWindow(sunrise.subtract(1, 'hour').valueOf(), sunset.add(1, 'hour').valueOf())
          }, 1000)
        } // else timeline.setWindow(moment(this.horaSalidaBase).subtract(1, 'hours'), moment(getSunset(this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD, new Date(this.horaSalidaBase))).add(1, 'hours'))
      }
    },
    getItems (tiempos, idTimeline) { // Crear los items de los medios de la timeline
      let item = {}
      let items = []
      let tiempo

      tiempos.forEach((t) => {
        t.PERIODOS.forEach((p) => {
          if (p.horaLlegadaInc !== 'Ocaso') {
            // Demora
            item = {}
            item.id = this.$uuid.createUUID()
            item.value = t.MEDIO
            item.group = t.ID_MEDIO
            item.content = 'Demora'
            item.start = this.$date.parseDate(p.horaSalidaBase).subtract(p.demora, 'minutes')
            item.className = idTimeline === 'real' ? 'demora' : 'demora-plan'
            item.end = p.horaSalidaBase
            item.title = (p.horaSalidaBase.$H < 9 ? '0' : '') + p.horaSalidaBase.$H + ':' + (p.horaSalidaBase.$m < 9 ? '0' : '') + p.horaSalidaBase.$m + ':' + (p.horaSalidaBase.$s < 9 ? '0' : '') + p.horaSalidaBase.$s // p.demora + 'min'
            items.push(item)

            // Ida a incendio
            item = {}
            item.id = this.$uuid.createUUID()
            item.value = t.MEDIO
            item.group = t.ID_MEDIO
            item.content = 'Ida'
            item.start = p.horaSalidaBase
            item.end = p.horaLlegadaInc
            item.className = idTimeline === 'real' ? 'transito' : 'transito-plan'
            tiempo = p.horaLlegadaInc.diff(p.horaSalidaBase, 'minutes')
            item.title = (p.horaLlegadaInc.$H < 9 ? '0' : '') + p.horaLlegadaInc.$H + ':' + (p.horaLlegadaInc.$m < 9 ? '0' : '') + p.horaLlegadaInc.$m + ':' + (p.horaLlegadaInc.$s < 9 ? '0' : '') + p.horaLlegadaInc.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
            items.push(item)

            if (p.finActuacionInc !== 'Ocaso') {
              // Incendio
              item = {}
              item.id = this.$uuid.createUUID()
              item.value = t.MEDIO
              item.group = t.ID_MEDIO
              item.content = 'Incendio'
              item.start = p.horaLlegadaInc
              item.end = p.finActuacionInc
              item.className = idTimeline === 'real' ? 'incendio' : 'incendio-plan'
              tiempo = p.finActuacionInc.diff(p.horaLlegadaInc, 'minutes')
              item.title = (p.finActuacionInc.$H < 9 ? '0' : '') + p.finActuacionInc.$H + ':' + (p.finActuacionInc.$m < 9 ? '0' : '') + p.finActuacionInc.$m + ':' + (p.finActuacionInc.$s < 9 ? '0' : '') + p.finActuacionInc.$s// tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
              items.push(item)

              if (p.horaLlegadaBase !== 'Ocaso') {
                // Vuelta a base
                item = {}
                item.id = this.$uuid.createUUID()
                item.value = t.MEDIO
                item.group = t.ID_MEDIO
                item.content = 'Vuelta'
                item.start = p.finActuacionInc
                item.end = p.horaLlegadaBase
                item.className = idTimeline === 'real' ? 'transito' : 'transito-plan'
                tiempo = p.horaLlegadaBase.diff(p.finActuacionInc, 'minutes')
                item.title = (p.horaLlegadaBase.$H < 9 ? '0' : '') + p.horaLlegadaBase.$H + ':' + (p.horaLlegadaBase.$m < 9 ? '0' : '') + p.horaLlegadaBase.$m + ':' + (p.horaLlegadaBase.$s < 9 ? '0' : '') + p.horaLlegadaBase.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
                items.push(item)

                // Descanso
                if (p.horaFinDescanso) {
                  item = {}
                  item.id = this.$uuid.createUUID()
                  item.value = t.MEDIO
                  item.group = t.ID_MEDIO
                  item.content = 'Descanso'
                  item.start = p.horaLlegadaBase
                  item.end = p.horaFinDescanso
                  item.className = idTimeline === 'real' ? 'descanso' : 'descanso-plan'
                  tiempo = p.horaFinDescanso.diff(p.horaLlegadaBase, 'minutes')
                  item.title = (p.horaFinDescanso.$H < 9 ? '0' : '') + p.horaFinDescanso.$H + ':' + (p.horaFinDescanso.$m < 9 ? '0' : '') + p.horaFinDescanso.$m + ':' + (p.horaFinDescanso.$s < 9 ? '0' : '') + p.horaFinDescanso.$s// tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min' // CAMBIAR POR FECHA Y HORA
                  items.push(item)

                  // Demora
                  // item = {}
                  // item.id = this.$uuid.createUUID()
                  // item.value = t.MEDIO
                  // item.group = t.ID_MEDIO
                  // item.content = 'Demora'
                  // item.start = p.horaFinDescanso
                  // item.className = idTimeline === 'real' ? 'demora' : 'demora-plan'
                  // item.end = moment(p.horaFinDescanso).add(p.demora, 'minutes')
                  // item.title = p.demora + 'min'
                  // items.push(item)
                }
              } else {
                items.pop() // Sacar los elementos de este periodo de este medio
                items.pop()
              }
            } else {
              items.pop() // Sacar los elementos de este periodo de este medio
            }
          }
        })
      })

      // Si es la real, añadir las actuaciones pasadas
      if (idTimeline === 'real') {
        this.tiemposRealPasado.forEach((t) => {
          t.PERIODOS.forEach((p) => {
            if (p.horaLlegadaInc !== 'Ocaso') {
              // Demora
              if (p.demora) {
                item = {}
                item.id = this.$uuid.createUUID()
                item.value = t.MEDIO
                item.group = t.ID_MEDIO
                item.content = 'Demora'
                item.start = this.$date.parseDate(p.horaSalidaBase).subtract(p.demora, 'minutes')
                item.className = idTimeline === 'real' ? 'demora' : 'demora-plan'
                item.end = p.horaSalidaBase
                item.title = p.demora + 'min'
                items.push(item)
              }

              // Ida a incendio
              item = {}
              item.id = this.$uuid.createUUID()
              item.value = t.MEDIO
              item.group = t.ID_MEDIO
              item.content = 'Ida'
              item.start = p.horaSalidaBase
              item.end = p.horaLlegadaInc
              item.className = idTimeline === 'real' ? 'transito' : 'transito-plan'
              tiempo = p.horaLlegadaInc.diff(p.horaSalidaBase, 'minutes')
              item.title = tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
              items.push(item)

              if (p.finActuacionInc !== 'Ocaso') {
                // Incendio
                item = {}
                item.id = this.$uuid.createUUID()
                item.value = t.MEDIO
                item.group = t.ID_MEDIO
                item.content = 'Incendio'
                item.start = p.horaLlegadaInc
                item.end = p.finActuacionInc
                item.className = idTimeline === 'real' ? 'incendio' : 'incendio-plan'
                tiempo = p.finActuacionInc.diff(p.horaLlegadaInc, 'minutes')
                item.title = tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
                items.push(item)

                if (p.horaLlegadaBase !== 'Ocaso') {
                  // Vuelta a base
                  item = {}
                  item.id = this.$uuid.createUUID()
                  item.value = t.MEDIO
                  item.group = t.ID_MEDIO
                  item.content = 'Vuelta'
                  item.start = p.finActuacionInc
                  item.end = p.horaLlegadaBase
                  item.className = idTimeline === 'real' ? 'transito' : 'transito-plan'
                  tiempo = p.horaLlegadaBase.diff(p.finActuacionInc, 'minutes')
                  item.title = tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
                  items.push(item)

                  // Descanso
                  if (p.horaFinDescanso) {
                    item = {}
                    item.id = this.$uuid.createUUID()
                    item.value = t.MEDIO
                    item.group = t.ID_MEDIO
                    item.content = 'Descanso'
                    item.start = p.horaLlegadaBase
                    item.end = p.horaFinDescanso
                    item.className = idTimeline === 'real' ? 'descanso' : 'descanso-plan'
                    tiempo = p.horaFinDescanso.diff(p.horaLlegadaBase, 'minutes')
                    item.title = tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
                    items.push(item)

                    // Demora
                    // if (p.demora) {
                    //   item = {}
                    //   item.id = this.$uuid.createUUID()
                    //   item.value = t.MEDIO
                    //   item.group = t.ID_MEDIO
                    //   item.content = 'Demora'
                    //   item.start = p.horaFinDescanso
                    //   item.className = idTimeline === 'real' ? 'demora' : 'demora-plan'
                    //   item.end = moment(p.horaFinDescanso).add(p.demora, 'minutes')
                    //   item.title = p.demora + 'min'
                    //   items.push(item)
                    // }
                  }
                } else {
                  items.pop() // Sacar los elementos de este periodo de este medio
                  items.pop()
                }
              } else {
                items.pop() // Sacar los elementos de este periodo de este medio
              }
            }
          })
        })
      }

      return items
    },

    getMedioById (idMedio) { // Devuelve el medio con el id dado
      return this.medios.find(x => x.ID_MEDIO.equalsIgnoreCase(idMedio))
    },

    // #region DIALOG ADD MEDIO
    showDialogAddMedio (idGroup) { // Muestra el dialog de añadir un medio a la timeline real
      // TODO: pasar al dialog la hora salida base seleccionada
      this.medioSelected = this.getMedioById(idGroup)
      this.showDialog = true
    },
    addMedioIncendio (data) { // Añade el medio a la timeline real y al incendio
      this.showDialog = false

      // Add medio al incendio
      if (data.mediosIncendio) {
        this.$store.dispatch('incendio/addMediosIncendio', data.mediosIncendio)
      }

      // Add medio a timeline real
      let dataMedio = data.dataMedio
      let medio = dataMedio.MEDIO

      medio.HORA_SALIDA_BASE = dataMedio.HORA_SALIDA_BASE // Guardo la hora de salida en el medio para luego añadirla a la lista de tiempos
      medio.ID_INCENDIO = this.incendioSelected.ID_INCENDIO
      // medio.vueltaAgoncillo = dataMedio.agoncillo

      if (this.timelinePlanificacion.groupsData) {
        this.timelinePlanificacion.groupsData._data.update({ id: medio.ID_MEDIO, visible: false }) // Quitar de la timeline de planificacion
      }
    },

    borrarMedioIncendio (idMedio, idMedioSector) { // Elimina el medio real de la timeline y del incendio
      let medio = this.getMedioById(idMedio)

      this.$root.$confirmDialog.open('¿Está seguro?', 'Eliminando el medio: ' + medio.MEDIO).then(result => {
        if (result) {
          let medioDelete = {
            ID_INCENDIO: this.incendioSelected.ID_INCENDIO,
            ID_MEDIO: idMedio,
            ID_SECTOR: null,
            ID_MEDIO_SECTOR: null
          }

          // Buscar ID_MEDIO_SECTOR e ID_SECTOR y borrar medio de incendio
          for (let i = 0; i < this.incendioSelected.SECTORES.length; i++) {
            let s = this.incendioSelected.SECTORES[i]

            let m = s.MEDIOS.find(x => x.ID_MEDIO_SECTOR === idMedioSector)
            if (m) {
              medioDelete.ID_MEDIO_SECTOR = m.ID_MEDIO_SECTOR
              medioDelete.ID_SECTOR = s.ID_SECTOR
            }
          }

          this.$store.dispatch('incendio/deleteMedioIncendio', medioDelete)
        }
      })
    },
    // #endregion

    async capturarGrafica () {
      let html = document.getElementById('timelineReal')
      let botones = html.getElementsByClassName('fabBtn')

      for (let i = 0; i < botones.length; i++) {
        botones[i].style.display = 'none'
      }

      htmlToImage.toJpeg(html, { quality: 0.95, backgroundColor: '#ffffff' })
        .then((dataUrl) => {
          let link = document.createElement('a')
          link.download = 'MMA_' + this.incendioSelected.MUNICIPIO + '_' + this.$date.now().format('YYYY_MM_DD_hh_mm') + '.jpeg'
          link.href = dataUrl
          link.click()

          for (let i = 0; i < botones.length; i++) {
            botones[i].style.display = 'inherit'
          }
        })
    },

    async sendGraficoTelegram () {
      let html = document.getElementById('timelineReal')

      htmlToImage.toJpeg(html, { quality: 0.95, backgroundColor: '#ffffff' })
        .then((dataUrl) => {
          let msg = {
            image: dataUrl.substr(22),
            user: this.$store.getters['usuario/currentUsuario'].USUARIO
          }

          api.others.sendImageTelegram(msg)
        })
    },

    cambiarTab (tab) { // TODO:
      if (tab === 'Gráfica') {
        setTimeout(() => {
          this.timelineReal.redraw()
          this.timelinePlanificacion.redraw()
        }, 800)
      }
    },

    /* Filtros */
    hhmm (value) {
      return value ? this.$date.formatDate(value, 'HH:mm') : null
    },
    horasmin (value) {
      return value ? Math.floor(value / 60) + 'h ' + value % 60 + 'min' : null
    },
    min (value) {
      let min = null
      if (value) {
        const hhmm = value.split(':')
        min = hhmm[0] * 60 + parseInt(hhmm[1])
      }
      return min
    },

    changeDescansoPopUp (data) {
      let medio = this.getMedioById(data.medioSelected.ID_MEDIO)

      let minutosDescanso = data.tiempoDescanso.split(':')[0] * 60 + parseInt(data.tiempoDescanso.split(':')[1])

      let descansoCustom = {
        ID_MEDIO_SECTOR: data.medioSelected.ID_MEDIO_SECTOR,
        INICIO_PERIODO: data.item.start,
        DURACION_PERIODO: minutosDescanso,
        TIPO: 'Descanso',
        oldDuracion: data.oldDuracion,
        orto: this.orto,
        ocaso: this.ocaso
      }

      this.$store.dispatch('incendio/editPeriodoDescanso', descansoCustom)

      this.reCalcularPeriodosPlanificacion(medio, descansoCustom)

      this.calcularDistanciasIncendio('real')

      this.actualizarDatos('real')
    },

    reCalcularPeriodosPlanificacion (medio, descanso) {
      let diferenciaDuracion = descanso.DURACION_PERIODO - descanso.oldDuracion
      let tiempoReal = this.mediosReal.find((a) =>
        a.ID_MEDIO === medio.ID_MEDIO
      )

      let tiempoAnterior = tiempoReal.PERIODOS_PLANIFICACION.find((a) => a.INICIO_PERIODO === descanso.INICIO_PERIODO.toISOString())

      if (!tiempoAnterior) {
        tiempoReal.PERIODOS_PLANIFICACION.push({
          ID_MEDIO_SECTOR: descanso.ID_MEDIO_SECTOR,
          INICIO_PERIODO: new Date(descanso.INICIO_PERIODO).toISOString(),
          DURACION_PERIODO: descanso.DURACION_PERIODO,
          TIPO: descanso.TIPO
        })
      }

      tiempoReal.PERIODOS_PLANIFICACION.forEach(element => {
        if (element.INICIO_PERIODO === descanso.INICIO_PERIODO.toISOString()) {
          element.DURACION_PERIODO = descanso.DURACION_PERIODO
        }
        if (this.$date.parseDate(element.INICIO_PERIODO) > this.$date.parseDate(descanso.INICIO_PERIODO.toISOString())) {
          element.INICIO_PERIODO = new Date(this.$date.parseDate(element.INICIO_PERIODO).add(diferenciaDuracion, 'minutes')).toISOString()
        }
      })
    }
  },

  beforeDestroy () {
    if (this.timelinePlanificacion) {
      this.timelinePlanificacion = null
      clearTimeout(this.timeout)
      clearInterval(this.timer)
    }

    if (this.timelineReal) {
      this.timelineReal = null
    }
  },

  mounted () {
    this.init()
  }
}
</script>

<style scoped>
  @import '../../../node_modules/vis-timeline/dist/vis-timeline-graph2d.min.css';

  ::v-deep .v-window__container,
  ::v-deep .v-window-item {
    height: calc(100vh - 260px);
  }

  /* Tamaño de las filas */
  /* ::v-deep .vis-label.medio {
    max-height: 35px !important;
  }
  ::v-deep .vis-group.medio {
    max-height: 35px !important;
  }
  ::v-deep .vis-item.vis-range {
    top: 2px !important;
  } */

  ::v-deep .vis-labelset .vis-label .vis-inner {
    width: 100%;
    max-width: 250px;
  }

  /* Texto demasiado grande */
  ::v-deep .group {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* Colores TODO: repetido en MMTT */
  ::v-deep .vis-item.transito  { background-color: #FFC400; }
  ::v-deep .vis-item.incendio  { background-color: #FF0000; }
  ::v-deep .vis-item.descanso { background-color: #FFFF00; }
  ::v-deep .vis-item.demora { background-color: #00AABB; }
  ::v-deep .vis-item.background-dia { background-color: rgba(170, 255, 248, 0.541); }
  ::v-deep .vis-item.background-noche { background-color: rgba(235, 170, 255, 0.329); }

  ::v-deep .vis-item.transito-plan  { background-color: rgba(255, 196, 0, 0.466); }
  ::v-deep .vis-item.incendio-plan  { background-color: rgba(255, 0, 0, 0.479); }
  ::v-deep .vis-item.descanso-plan { background-color: rgba(255, 255, 0, 0.479); }
  ::v-deep .vis-item.demora-plan { background-color: rgba(0, 171, 187, 0.521); }

  /* Tooltip */
  ::v-deep .group .tooltiptext {
    position: absolute;
    top: 10px;
    right: 30px;
    font-size: 12px !important;
    text-align: left!important;
    visibility: hidden;
    padding: 5px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    z-index: 1;
  }

  ::v-deep .group:hover .tooltiptext {
    visibility: visible;
    position: absolute;
  }

  /* Boton */
  ::v-deep .fabBtn {
    float: left;
    background: #00759e;
    background-color: #00759e;
    width: 24px;
    height: 24px;
    margin-right: 6px;
    border-radius: 100%;
    border: none;
    outline: none;
    color: #FFF;
    font-size: medium;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: .3s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  ::v-deep .fabBtn:focus {
    transform: scale(1.1);
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  ::v-deep .fabBtn:disabled {
    background-color: #9e2000;
  }

  /* Checkbox agoncillo */
  ::v-deep .cbAgoncillo {
    margin-left: 2px;
    margin-right: 5px;
  }

  ::v-deep .v-window {
    overflow: auto;
  }
</style>
